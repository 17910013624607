import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Button,
  VStack,
  useToast,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import InputForm from "components/InputForm";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { editMaquineta } from "services/api.service";

const ModalEditMaquineta: FC<{
  data: {
    id: string;
    name: string;
    number: string;
    terminalId: string;
    [key: string]: any;
  };
  onClose: () => void;
}> = ({ data, onClose: onCloseFunc }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, setValue } = useForm<{
    name: string;
    number: string;
    terminalId: string;
  }>();

  const save = async (dataForm: {
    name: string;
    number: string;
    terminalId: string;
  }) => {
    setLoading(true);
    editMaquineta(data.id, {
      name: dataForm.name,
      number: dataForm.number,
      terminalId: dataForm.terminalId,
    })
      .then(() => {
        onClose();
        onCloseFunc();
        toast({
          title: "Sucesso",
          description: "Terminal alterado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar vínculo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };
  useEffect(() => {
    if (isOpen) {
      setValue("name", data?.name);
      setValue("number", data?.number);
      setValue("terminalId", data?.terminalId);
    }
  }, [isOpen, data, setValue]);

  return (
    <>
      <Tooltip label="Editar" aria-label="Visualizar">
        <IconButton
          aria-label="Alterar"
          rounded="md"
          size="sm"
          variant="outline"
          icon={<FaEdit />}
          onClick={() => {
            onOpen();
          }}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Alterar Terminal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack mb={5} width="100%" alignItems="start">
              <InputForm
                control={control}
                name="name"
                label="Nome"
                type="text"
                placeholder="Nome"
                rules={{ required: "Campo obrigatório" }}
              />
              <InputForm
                control={control}
                name="number"
                label="Serial"
                type="text"
                placeholder="Serial"
                rules={{ required: "Campo obrigatório" }}
              />
              <InputForm
                control={control}
                name="terminalId"
                label="Id Terminal"
                type="text"
                placeholder="Id Terminal"
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={4}>
              <Button
                colorScheme="red"
                isLoading={loading}
                onClick={() => {
                  onClose();
                }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="green"
                isLoading={loading}
                onClick={handleSubmit(save)}
              >
                Salvar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalEditMaquineta;
