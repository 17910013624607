// Chakra imports
import {
  Badge,
  Box,
  ButtonGroup,
  HStack,
  SimpleGrid,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import { useMemo } from "react";
import { Column } from "react-table";
import TableComponent from "components/TableComponent";
import SeeWithdrawel from "./components/SeeWithdrawel";
import { findAllWithdrawers } from "services/api.service";
import { maskDocument } from "utils/formatters";
import EditWithdrawel from "./components/EditWithdrawel";
import { DateTime } from "luxon";
import useAuth, { UserPermissions } from "contexts/useAuth";

export interface CustomerAccounts {
  account: string;
  agency: string;
  bankIspb: string;
  pix: string;
  id: string;
  accountHolder: string;
  accountHolderDocument: string;
}

export interface Customer {
  id: string;
  name: string;
  document_number: string;
}

export interface Withdrawal {
  amount: string;
  createdAt: Date;
  fee: string;
  description: string;
  status: "PENDING" | "IN_PAYMENT" | "FINISHED" | "CANCELLED";
  customerId: string;
  id: string;
  type: "BILLET" | "MANU" | "DICT" | "QRCODE";
  billetCode?: string;
  imageUrl?: string;
  CustomerAccounts?: CustomerAccounts;
  Customer: Customer;
  WithdrawelSulcredi: WithdrawelSulcredi[];
}
export interface WithdrawelSulcredi {
  id: string;
  withdrawelId: string;
  status: number;
  endToEndId: string;
  errorCode: any;
  message: any;
  payeeName: any;
  payeeDocument: any;
  amount: string;
  integrationId: string;
  payload: any;
  createdAt: string;
  updatedAt: string;
}

export enum StatusWithdrawal {
  PENDING = "Pendente",
  IN_PAYMENT = "Em Pagamento",
  FINISHED = "Finalizado",
  CANCELED = "Cancelado",
}

const mapType = {
  BILLET: "Boleto",
  PIX: "Pix",
  MANU: "Pix Manual",
  DICT: "Pix (DICT)",
  QRCODE: "Pix (Copia e Cola)",
};

export default function Withdrawals() {
  const { userData, hasPermission } = useAuth();
  const hasPerm = hasPermission(UserPermissions.WITHDRAWALS_WRITE);

  const columns = useMemo(
    () =>
      [
        {
          Header: "Cliente",
          accessor: "Customer.name",
        },
        {
          Header: "Documento",
          accessor: "Customer.document_number",
          Cell: ({ value }: any) => {
            return maskDocument(value);
          },
        },

        {
          Header: "Tipo",
          accessor: "type",
          align: "right",
          Cell: ({ value }) => {
            return mapType[value as keyof typeof mapType];
          },
        },
        {
          Header: "Descrição",
          accessor: "description",
          Cell: ({ value }: any) => {
            return (
              <Tooltip label={value}>
                <Text
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  maxW="120px"
                >
                  {value}
                </Text>
              </Tooltip>
            );
          },
        },
        {
          Header: "Valor do Saque",
          accessor: "amount",
          align: "right",
          Cell: ({ value }) => {
            return (+value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        {
          Header: "Taxa",
          accessor: "fee",
          Cell: ({ value, row }: any) => {
            if (row.original?.type === "PERCENTUAL") {
              return `${value}%`;
            }

            return (+value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },

        {
          Header: "Status",
          align: "right",
          filter: true,
          values: [
            {
              value: "PENDING",
              label: StatusWithdrawal.PENDING,
            },
            {
              value: "IN_PAYMENT",
              label: StatusWithdrawal.IN_PAYMENT,
            },
            {
              value: "FINISHED",
              label: StatusWithdrawal.FINISHED,
            },
            {
              value: "CANCELED",
              label: StatusWithdrawal.CANCELED,
            },
          ],
          accessor: "status",
          Cell: ({ value }) => {
            let colorScheme = "yellow";
            if (value === "CANCELED") {
              colorScheme = "red";
            }
            if (value === "FINISHED") {
              colorScheme = "green";
            }

            return (
              <Badge colorScheme={colorScheme}>
                {StatusWithdrawal[value as keyof typeof StatusWithdrawal]}
              </Badge>
            );
          },
        },

        {
          Header: "Criado em",
          accessor: "createdAt",
          Cell: ({ value }: any) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },
        {
          Header: "Dados",
          accessor: "CustomerAccounts.id",
          Cell: ({ value, row }: any) => {
            const internal = row.original.internal;
            const antecipacao = !value && row.original.type !== "BILLET" && row.original.type !== 'QRCODE';

            return (
              <HStack>
                {antecipacao ? (
                  <Badge colorScheme={"blue"}>Antecipação</Badge>
                ) : null}
                {internal ? (
                  <Badge colorScheme={internal ? "yellow" : "green"}>
                    {"Interna"}
                  </Badge>
                ) : null}
              </HStack>
            );
          },
        },
        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: Withdrawal) => (
    <ButtonGroup key={row.id}>
      {userData.isCustomerAdmin ? (
        <SeeWithdrawel data={row}></SeeWithdrawel>
      ) : (
        <>
          <SeeWithdrawel data={row}></SeeWithdrawel>
          {hasPerm && <EditWithdrawel data={row}></EditWithdrawel>}
        </>
      )}
    </ButtonGroup>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          queryFn={findAllWithdrawers}
          queryKey="findAllWithdrawers"
          filterable={["createdAt", "customerId", "status"]}
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
