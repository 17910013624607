import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import useAuth from "contexts/useAuth";
import React from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa";
import { changePasswordCustomer } from "services/api.service";
export interface ChangePasswordCustomerForm {
  password: string;
  currentPassword?: string;
}

const ChangePasswordCustomer: React.FC<{
  customerId: string;
}> = ({ customerId }) => {
  const {
    onOpen: onOpenChangePassword,
    isOpen: isOpenChangePassword,
    onClose: onCloseChangePass,
  } = useDisclosure();

  const toast = useToast();
  const {
    userData: { id },
  } = useAuth();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordCustomerForm>({
    defaultValues: {
      password: "",
    },
  });

  const onClose = () => {
    onCloseChangePass();
    reset();
  };

  const onSubmit = (dataRequest: ChangePasswordCustomerForm) => {
    let request = changePasswordCustomer(
      customerId || id,
      dataRequest.password,
      dataRequest.currentPassword
    );

    request
      .then(() => {
        toast({
          title: "Succeso",
          description: `Senha Alterada com Sucesso`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.response.data.message,
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Button
        onClick={onOpenChangePassword}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPen></FaPen>
        <Text ml={2}>Alterar Senha</Text>
      </Button>
      {isOpenChangePassword && (
        <Portal>
          <Modal isOpen={isOpenChangePassword} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Alterar Senha</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <VStack spacing={5}>
                    <FormControl isInvalid={!!errors.password?.message}>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        mb="8px"
                      >
                        Nova Senha<Text>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        {...register("password", {
                          required: "Esse Campo é Obrigatório",
                          minLength: {
                            message:
                              "Esse campo deve ter no mínimo 8 caracteres",
                            value: 8,
                          },
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        type="password"
                        placeholder="Digite a nova senha"
                        fontWeight="500"
                        size="lg"
                        required
                      />
                      <FormErrorMessage>
                        {errors.password?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <Button
                      type="submit"
                      variant={"outline"}
                      w={`100%`}
                      color={"green"}
                    >
                      Salvar
                    </Button>
                  </VStack>
                </form>
              </ModalBody>

              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        </Portal>
      )}
    </>
  );
};

export default ChangePasswordCustomer;
