import { CloseIcon } from "@chakra-ui/icons";
import {
  useToast,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  IconButton,
} from "@chakra-ui/react";
import React from "react";
import { cancelCard } from "services/api.service";
const RemoveCard: React.FC<{ id: string; refetch: () => void }> = ({
  id,
  refetch,
}) => {
  const toast = useToast();
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="Cancelar cartão"
          colorScheme="red"
          size="sm"
          ml={3}
          variant="ghost"
          rounded={"sm"}
          icon={<CloseIcon />}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Confirmação!</PopoverHeader>
        <PopoverBody>
          Deseja realmente cancelar o cartão?
          <Button
            size="sm"
            colorScheme="red"
            ml={3}
            rounded={"sm"}
            onClick={() => {
              cancelCard(id)
                .then(() => {
                  toast({
                    title: "Sucesso",
                    description: "Cartão cancelada com sucesso",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                  refetch();
                })
                .catch((err) => {
                  toast({
                    title: "Erro",
                    description:
                      err.response?.data?.message || "Erro ao cancelar cartão",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                });
            }}
          >
            Cancelar cobrança
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default RemoveCard;
