// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/users/components/UsuariosSistema";
import { useMemo } from "react";
import { findAllUsers } from "services/api.service";
import { useQuery } from "@tanstack/react-query";

export default function Users() {
  const { data, refetch } = useQuery(["users"], findAllUsers);

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "Telefone",
        accessor: "phone",
      },
      {
        Header: "Grupo Permissão",
        accessor: "Groups.name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Empresa",
        accessor: "Company.name",
      },
      {
        Header: "Status",
        accessor: "ative",
      },
      {
        Header: "Ações",
        accessor: "actions",
      },
    ],
    []
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <DevelopmentTable
          columnsData={columns}
          tableData={data || []}
          refetch={refetch}
        />
      </SimpleGrid>
    </Box>
  );
}
