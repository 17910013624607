import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { ChargeItens } from "types/Charge";
import { maskCurrency } from "utils/number";

const ListItens: React.FC<{
  itens: ChargeItens[];
}> = ({ itens }) => {
  return (
    <Box bg="gray.100" p={4} borderRadius="md" my={2}>
      <Text fontSize="md" mb={4} fontWeight="bold">
        Itens
      </Text>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th>Preço Unitário</Th>
              <Th>Quantidade</Th>
              <Th>Valor</Th>
              <Th>Taxa</Th>
            </Tr>
          </Thead>
          <Tbody>
            {itens.map((item) => (
              <Tr key={item.id}>
                <Td>{item.name}</Td>
                <Td>{maskCurrency(item.unitPrice)}</Td>
                <Td>{item.quantity}</Td>
                <Td>{maskCurrency(item.amount)}</Td>
                <Td>{item.feePercent ?? 0}%</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ListItens;
