// Chakra imports
import { Box, SimpleGrid, Text } from "@chakra-ui/react";

export default function NoPermission() {
  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Box>
          <Text fontSize="xl" fontWeight="bold">
            Permissão negada
          </Text>
          <Text fontSize="md" color="gray.500">
            Você não tem permissão para acessar esta página.
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
}
