import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import React from "react";
import { useForm } from "react-hook-form";
import {
  findAllClients,
  relatedMovementToCustomer,
} from "services/api.service";
import { Movement } from "types/Movement";
import { maskCurrency } from "utils/number";

const LabelItem: React.FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <VStack mb={3} width="100%" alignItems="start">
      <Text as={"small"} fontSize="sm" color="gray.600">
        {label}
      </Text>
      <Text mt={"0px !important"} fontSize="md">
        {value || "---"}
      </Text>
    </VStack>
  );
};

const RelateClient: React.FC<{ movement: Movement; onClose: () => void }> = ({
  movement,
  onClose,
}) => {
  const [loading, setLoading] = React.useState(false);
  const toast = useToast();

  const { control, handleSubmit } = useForm<{ customer: SelectOptionType }>({});

  const save = (data: { customer: SelectOptionType }) => {
    setLoading(true);

    relatedMovementToCustomer({
      movementId: movement.id,
      customerId: data.customer.value,
    })
      .then(() => {
        toast({
          title: "Sucesso",
          description: "Movimento relacionado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao relacionar movimento",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={!!movement} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Alterar Terminal</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack mb={5} width="100%" alignItems="start">
            <LabelItem label="Transação" value={movement?.transactionCode} />
            <LabelItem label="Valor" value={maskCurrency(+movement.amount)} />
          </VStack>
          <FormRemoteSelectInput
            control={control}
            label="Cliente"
            required
            registerOptions={{ required: "Esse campo é obrigatório" }}
            loadDataFn={({ value, cb }) =>
              findAllClients({ filter: value }).then((retorno) => {
                if (retorno?.registers?.length > 0) {
                  cb(
                    retorno.registers?.map((d: any) => ({
                      label: d.name,
                      value: d.id,
                    }))
                  );
                } else {
                  cb([]);
                }
              })
            }
            name="customer"
          />
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              colorScheme="red"
              isLoading={loading}
              onClick={() => {
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              isLoading={loading}
              onClick={handleSubmit(save)}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
        ;
      </ModalContent>
    </Modal>
  );
};

export default RelateClient;
