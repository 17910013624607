import {
  Button,
  Checkbox,
  Divider,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { PermDescriptions, ReadWrite, UserPermissions } from "contexts/useAuth";
import React, { useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { savePermissionsGroup } from "services/api.service";

const SeePermissions: React.FC<{
  permissions: UserPermissions[];
  id: number;
  disabled?: boolean;
  refetch: () => void;
}> = ({ permissions, id, refetch, disabled }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [perms, setPerms] = React.useState<UserPermissions[]>(permissions);

  useEffect(() => {
    if (isOpen) {
      setPerms(permissions);
    }
  }, [isOpen, permissions]);

  const splitedPerms = new Array(
    ...new Set(Object.values(UserPermissions).map((p) => p.split(".")[0]))
  );

  const handleSave = async () => {
    setLoading(true);
    await savePermissionsGroup(id, perms)
      .then(() => {
        refetch();
        toast({
          title: "Permissões salvas com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.message ?? "Erro ao salvar permissões",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <IconButton
        aria-label="Ver Saque"
        rounded="md"
        size="sm"
        variant="outline"
        icon={<FaEye />}
        onClick={() => {
          onOpen();
        }}
      />
      {isOpen && (
        <>
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={{
              base: "full",
              md: "xl",
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Permissões</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack alignItems={`center`} spacing={5}>
                  {splitedPerms.map((key) => (
                    <>
                      <Divider />
                      <Text width="100%" fontWeight="bold">{PermDescriptions[key]}</Text>
                      <HStack justifyContent={"space-around"} w={"100%"}>
                        {Object.values(UserPermissions)
                          .filter((p) => p.split(".")[0] === key)
                          .map((p) => (
                            <Checkbox
                              isDisabled={disabled}
                              disabled={disabled}
                              w={"100%"}
                              key={p}
                              colorScheme="red"
                              isChecked={
                                perms.find((perm) => perm === p) ? true : false
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setPerms([...perms, p]);
                                } else {
                                  setPerms(perms.filter((perm) => perm !== p));
                                }
                              }}
                            >
                              {ReadWrite[p.split(".")[1]]}
                            </Checkbox>
                          ))}
                      </HStack>
                    </>
                  ))}
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button
                  aria-label="Salvar"
                  rounded="md"
                  size="sm"
                  variant="outline"
                  onClick={handleSave}
                  isLoading={loading}
                  isDisabled={disabled}
                >
                  Salvar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default SeePermissions;
