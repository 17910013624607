import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import InputNumber from "components/InputNumber";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { createCardFill } from "services/api.service";

type FormValues = { amount: number };

const RecarregarCartao: FC<{ cardId: string; refetch: () => void }> = ({
  cardId,
  refetch,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit } = useForm<FormValues>();

  const save = async (dataForm: FormValues) => {
    setLoading(true);
    createCardFill(cardId, {
      ...dataForm,
    })
      .then(() => {
        refetch();
        onClose();
        toast({
          title: "Sucesso",
          description: "Saldo adicionada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar vínculo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CustomButton onClick={onOpen} colorScheme="brand" leftIcon={<FaPlus />}>
        <Text ml={2}>Recarregar</Text>
      </CustomButton>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Recarregar Cartão</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={3}>
                Informe o valor que deseja recarregar. O Valor será retirado do
                saldo do cliente.
              </Text>
              <InputNumber
                control={control}
                label="Recarga"
                name="amount"
                rules={{ required: "Campo obrigatório" }}
              />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default RecarregarCartao;
