import { Link } from "@chakra-ui/react";
import React from "react";

const LinkToPage: React.FC<{
  href: string;
  name: string;
}> = ({ href, name }) => {
  return (
    <Link
      textDecorationLine={`underline`}
      _hover={{ cursor: `pointer` }}
      textDecorationStyle={`dashed`}
      textDecorationThickness={`1px`}
      fontSize={16}
      href={href}
    >
      {name}
    </Link>
  );
};

export default LinkToPage;
