import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import { AsyncProps } from "chakra-react-select";
import React, { PropsWithoutRef } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import FormLabelCustom from "./FormLabel";
interface InputProps
  extends PropsWithoutRef<Omit<AsyncProps<any, any, any>, `onChange`>> {
  control: Control<any>;
  label: string;
  labelProps?: any;
  type:
    | "password"
    | "text"
    | "email"
    | "number"
    | "datetime-local"
    | "tel"
    | "date";
  name: string;
  placeholder?: string;
  beatify?: boolean;
  autoFocus?: boolean;
  rules?: RegisterOptions<any>;
  onChange?: (e: string) => void;
}
const InputForm: React.FC<InputProps> = ({
  control,
  label,
  rules,
  name,
  placeholder,
  type,
  beatify,
  autoFocus,
  labelProps = {},
  onChange: customOnChange,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <FormControl isInvalid={invalid}>
          <FormLabelCustom label={label} name={name} props={labelProps} />
          <Input
            name={name}
            value={value}
            {...props}
            size={beatify ? "md" : "sm"}
            autoFocus={autoFocus}
            onChange={(e) => {
              onChange(e);
              customOnChange && customOnChange(e.target.value);
            }}
            type={type}
            fontSize={beatify ? "md" : "sm"}
            ms={{ base: "0px", md: "0px" }}
            placeholder={placeholder}
          />
          <FormErrorMessage>
            {error?.message || "Campo inválido"}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default InputForm;
