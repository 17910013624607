// Chakra imports
import { Badge, Box, Flex, IconButton, SimpleGrid } from "@chakra-ui/react";

import { useMemo } from "react";
import { findAllCharges } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import TableComponent from "components/TableComponent";
import { FaEye } from "react-icons/fa";
import { ChargeType, ItemChargeStatus } from "types/Charge";
import CreateCharge from "./CreateCharge";
import useAuth, { UserPermissions } from "contexts/useAuth";

export enum ChargeStatus {
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  PAID = "PAID",
  CANCELED = "CANCELED",
}

export enum CreditCardStatus {
  PENDING = "PENDING",
  PAID = "PAID",
  CANCELED = "CANCELED",
  REFUNDED = "REFUNDED",
  CONFIRMED = "CONFIRMED",
  AUTHORIZED = "AUTHORIZED",
}

export const StatusRender = (status: ChargeStatus) => {
  if (status === ChargeStatus.PENDING)
    return <Badge colorScheme="orange">Pendente</Badge>;
  if (status === ChargeStatus.PAID)
    return <Badge colorScheme="green">Pago</Badge>;
  if (status === ChargeStatus.REFUNDED)
    return <Badge colorScheme="red">Devolvido</Badge>;
  if (status === ChargeStatus.PARTIALLY_REFUNDED)
    return <Badge colorScheme="red">Devolvido Parcialmente</Badge>;
  if (status === ChargeStatus.CANCELED)
    return <Badge colorScheme="red">Cancelado</Badge>;
};

export const BilletPixStatusRender = (status: ItemChargeStatus) => {
  if (status === ItemChargeStatus.PENDING)
    return <Badge colorScheme="orange">Pendente</Badge>;
  if (status === ItemChargeStatus.FINISHED)
    return <Badge colorScheme="green">Pago</Badge>;
  if (status === ItemChargeStatus.CANCELED)
    return <Badge colorScheme="red">Cancelado</Badge>;
  if (status === ItemChargeStatus.REFUNDED)
    return <Badge colorScheme="red">Devolvido</Badge>;
  else return <Badge colorScheme="red">Erro</Badge>;
};
export const CreditCardStatusRender = (status: CreditCardStatus) => {
  if (status === CreditCardStatus.PENDING)
    return <Badge colorScheme="orange">Pendente</Badge>;
  if (
    [
      CreditCardStatus.AUTHORIZED,
      CreditCardStatus.PAID,
      CreditCardStatus.CONFIRMED,
    ].includes(status)
  )
    return <Badge colorScheme="green">Pago</Badge>;
  if (status === CreditCardStatus.CANCELED)
    return <Badge colorScheme="red">Cancelado</Badge>;
  if (status === CreditCardStatus.REFUNDED)
    return <Badge colorScheme="red">Devolvido</Badge>;
  else return <Badge colorScheme="red">Erro</Badge>;
};

export default function Charges() {
  const history = useHistory();

  const { hasPermission } = useAuth();

  const createPermission = hasPermission(
    UserPermissions.CHARGES_CREATE,
    UserPermissions.CHARGES_WRITE
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: "#Id",
          accessor: "sequence",
        },
        {
          Header: "Cliente",
          accessor: "CustomerClient.name",
        },
        {
          Header: "Descrição",
          accessor: "description",
        },
        {
          Header: "Status",
          accessor: "status",
          filter: true,
          values: [
            {
              value: ChargeStatus.PAID,
              label: "Pago",
            },
            {
              value: ChargeStatus.PENDING,
              label: "Pendente",
            },
            {
              value: ChargeStatus.CANCELED,
              label: "Cancelado",
            },
          ],
          Cell: ({ value }: { value: ChargeStatus }) => {
            return StatusRender(value);
          },
        },
        {
          Header: "Tipo",
          accessor: "chargeType",
          Cell: ({ value }: { value: ChargeType }) => {
            return ChargeType[value];
          },
        },
        {
          Header: "Valor",
          accessor: "amount",
          align: "right",
          Cell: ({ value }) => {
            return (+value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },

        {
          accessor: "createdAt",
          Header: "Data",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: any) => (
    <Flex align="center" justify="flex-start" w="100%">
      <IconButton
        aria-label="Apertar"
        rounded="md"
        size="sm"
        variant="outline"
        icon={<FaEye />}
        onClick={() => history.push(`/admin/charge/${row.id}`)}
      />
    </Flex>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          filterable={["customerId", "createdAt"]}
          queryFn={findAllCharges}
          queryKey="findAllCharges"
          right={<>{createPermission && <CreateCharge></CreateCharge>}</>}
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
