import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import SwithForm from "components/SwithForm";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { createCard } from "services/api.service";

type FormValues = {
  finalNumber: string;
  description: string;
  limit: number;
  status: boolean;
  invoiceClosingDay: number;
  automaticRenewal: boolean;
  fillAmount: boolean;
};

const CreateCard: FC<{ customerId: string; refetch: () => void }> = ({
  customerId,
  refetch,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      status: true,
      automaticRenewal: true,
    },
  });

  const save = async (dataForm: FormValues) => {
    setLoading(true);
    createCard({
      ...dataForm,
      customerId,
    })
      .then(() => {
        refetch();
        onClose();
        toast({
          title: "Sucesso",
          description: "Cartão adicionada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao adicionar cartão",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPlus />
        <Text ml={2}>Criar cartão</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Novo Cartão</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SwithForm control={control} label="Status" name="status" />

              <InputForm
                control={control}
                label="Últimos 4 dígitos"
                name="finalNumber"
                type="text"
                rules={{ required: "Campo obrigatório", maxLength: 4 }}
              />
              <InputForm
                control={control}
                label="Descrição"
                name="description"
                type="text"
                rules={{ required: "Campo obrigatório" }}
              />
              <InputNumber
                control={control}
                label="Limite"
                name="limit"
                rules={{ required: "Campo obrigatório" }}
              />

              <InputForm
                type="number"
                control={control}
                label="Dia de fechamento da fatura"
                name="invoiceClosingDay"
                rules={{ required: "Campo obrigatório", min: 1, max: 31 }}
              />

              <SwithForm
                control={control}
                label="Renovação automática"
                name="automaticRenewal"
              />

              <InputNumber
                control={control}
                label="Preenchimento de valor"
                name="fillAmount"
                rules={{ required: "Campo obrigatório" }}
              />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreateCard;
