import {
  Alert,
  VStack,
  HStack,
  AlertIcon,
  Heading,
  Button,
  Text,
  useToast,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import React, { FC, useCallback } from "react";
import { changeStatusCustomer, findCnpj } from "services/api.service";
import { maskCurrency } from "utils/number";

const StatusCustomer: FC<{
  id: string;
  status: number;
  document: string;
  refetch: () => void;
}> = ({ id, document, status, refetch }) => {
  const toast = useToast();
  const { data, isLoading } = useQuery(
    ["findCnpj", id],
    () => findCnpj(document),
    {
      enabled: status === 2,
    }
  );

  const changeStatus = useCallback(
    (id: string, ative: number) => {
      changeStatusCustomer(id, ative).then(() => {
        refetch();
        toast({
          title: "Usuário alterado com sucesso.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      });
    },
    [refetch, toast]
  );

  const {
    razao_social,
    cnpj_raiz,
    capital_social,
    responsavel_federativo,
    atualizado_em,
    porte,
    natureza_juridica,
    qualificacao_do_responsavel,
    socios,
    estabelecimento,
  } = data || {};

  return (
    status === 2 && (
      <Alert status="warning" variant="top-accent">
        <VStack alignItems="flex-start">
          <HStack>
            <AlertIcon />
            <Heading fontSize={24}>Cadastro pendente de aprovação.</Heading>
          </HStack>
          {isLoading && <Text>Carregando...</Text>}
          {!isLoading && !data?.razao_social && (
            <Text>Esse cadastro é referente à Pessoa Física.</Text>
          )}
          {!isLoading && data?.razao_social && (
            <SimpleGrid>
              <Heading size="lg">{razao_social}</Heading>
              <Text>CNPJ Raiz: {cnpj_raiz}</Text>
              <Text>Capital Social: {maskCurrency(+capital_social)}</Text>
              <Text>
                Responsável Federativo:{" "}
                {responsavel_federativo || "Não informado"}
              </Text>
              <Text>
                Atualizado em:{" "}
                {atualizado_em
                  ? DateTime.fromISO(atualizado_em).toFormat(
                      "dd/MM/yyyy HH:mm:ss"
                    )
                  : ""}
              </Text>
              <Text>Porte: {porte.descricao}</Text>
              <Text>Natureza Jurídica: {natureza_juridica.descricao}</Text>
              <Text>
                Qualificação do Responsável:{" "}
                {qualificacao_do_responsavel.descricao}
              </Text>

              <Heading size="md" mt={4}>
                Sócios
              </Heading>
              {socios.map((socio) => (
                <Box key={socio.cpf_cnpj_socio} mt={2}>
                  <Text>Nome: {socio.nome}</Text>
                  <Text>CPF/CNPJ: {socio.cpf_cnpj_socio}</Text>
                  <Text>Tipo: {socio.tipo}</Text>
                  <Text>
                    Data de Entrada:{" "}
                    {socio.data_entrada
                      ? DateTime.fromISO(socio.data_entrada).toFormat(
                          "dd/MM/yyyy"
                        )
                      : ""}
                  </Text>
                  <Text>
                    Qualificação do Sócio: {socio.qualificacao_socio.descricao}
                  </Text>
                </Box>
              ))}

              <Heading size="md" mt={4}>
                Estabelecimento
              </Heading>
              <Text>CNPJ: {estabelecimento.cnpj}</Text>
              <Text>
                Atividade Principal:{" "}
                {estabelecimento.atividade_principal.descricao}
              </Text>
              <Text>
                Situação Cadastral: {estabelecimento.situacao_cadastral}
              </Text>
              <Text>
                Data da Situação Cadastral:{" "}
                {estabelecimento.data_situacao_cadastral
                  ? DateTime.fromISO(
                      estabelecimento.data_situacao_cadastral
                    ).toFormat("dd/MM/yyyy")
                  : ""}
              </Text>
              <Text>
                Data de Início de Atividade:{" "}
                {estabelecimento.data_inicio_atividade
                  ? DateTime.fromISO(
                      estabelecimento.data_inicio_atividade
                    ).toFormat("dd/MM/yyyy")
                  : ""}
              </Text>
              <Text>Tipo de Logradouro: {estabelecimento.tipo_logradouro}</Text>
              <Text>Logradouro: {estabelecimento.logradouro}</Text>
              <Text>Número: {estabelecimento.numero}</Text>
              <Text>
                Complemento: {estabelecimento.complemento || "Não informado"}
              </Text>
              <Text>Bairro: {estabelecimento.bairro}</Text>
              <Text>CEP: {estabelecimento.cep}</Text>
              <Text>DDD: {estabelecimento.ddd1}</Text>
              <Text>Telefone: {estabelecimento.telefone1}</Text>
              <Text>E-mail: {estabelecimento.email}</Text>

              <Heading size="md" mt={4}>
                Inscrições Estaduais
              </Heading>
              {estabelecimento.inscricoes_estaduais.map((ie) => (
                <Box key={ie.inscricao_estadual} mt={2}>
                  <Text>Inscrição Estadual: {ie.inscricao_estadual}</Text>
                  <Text>Ativo: {ie.ativo ? "Sim" : "Não"}</Text>
                  <Text>
                    Data de Atualização:{" "}
                    {ie.atualizado_em
                      ? DateTime.fromISO(ie.atualizado_em).toFormat(
                          "dd/MM/yyyy HH:mm:ss"
                        )
                      : ""}
                  </Text>

                  <Text>Estado: {ie.estado.nome}</Text>
                </Box>
              ))}
            </SimpleGrid>
          )}

          <HStack>
            <Button
              colorScheme="brand"
              size="sm"
              variant="outline"
              onClick={() => changeStatus(id, 1)}
            >
              Aprovar
            </Button>
            <Button
              colorScheme="red"
              size="sm"
              variant="outline"
              onClick={() => changeStatus(id, 9)}
            >
              Recusar
            </Button>
          </HStack>
        </VStack>
      </Alert>
    )
  );
};

export default StatusCustomer;
