import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Badge,
  Box,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Code,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import { WebhookCharge } from "types/Charge";

const PayloadViewer: React.FC<{
  payload: Record<string, any>;
}> = ({ payload }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        size="sm"
        colorScheme="brand"
        rounded={"sm"}
        variant={"outline"}
        border={"sm"}
        onClick={() => onOpen()}
      >
        Dados enviados
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payload</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow="auto">
            <Code>
              <pre>{JSON.stringify(payload, null, 2)}</pre>
            </Code>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="brand" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const MapStatusHttp: React.FC<{ status: number }> = ({ status }) => {
  if (status > 430) {
    return <Badge colorScheme="red">Erro</Badge>;
  }
  if (status > 299) {
    return <Badge colorScheme="orange">Atenção</Badge>;
  }
  return <Badge colorScheme="green">Finalizado</Badge>;
};

const Webhooks: React.FC<{
  webhooks: WebhookCharge[];
}> = ({ webhooks }) => {
  return (
    <Box bg="gray.100" p={4} borderRadius="md" my={2}>
      <Text fontSize="md" mb={4} fontWeight="bold">
        Notificações de API
      </Text>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Url</Th>
              <Th align="center">Status</Th>
              <Th>Criação</Th>
              <Th>Atualização</Th>
              <Th>Payload</Th>
            </Tr>
          </Thead>
          <Tbody>
            {webhooks.map((webhook) => (
              <Tr key={webhook.id}>
                <Td>{webhook.url}</Td>
                <Td>
                  <MapStatusHttp status={webhook.status} />
                </Td>
                <Td>
                  {DateTime.fromISO(webhook.createdAt).toFormat(
                    "dd/MM/yyyy HH:mm:ss.S"
                  )}
                </Td>
                <Td>
                  {webhook.updatedAt
                    ? DateTime.fromISO(webhook.updatedAt).toFormat(
                        "dd/MM/yyyy HH:mm:ss.S"
                      )
                    : ""}
                </Td>
                <Td>
                  <PayloadViewer payload={webhook.payload} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Webhooks;
