import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
  VStack,
  ButtonGroup,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaMinus, FaPlus } from "react-icons/fa";
import { createMovementCard } from "services/api.service";

type FormValues = { itens: { description: string; amount: number }[] };

const CreateCardMovements: FC<{ cardId: string; refetch: () => void }> = ({
  cardId,
  refetch,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>();
  const [quantity, setQuantity] = React.useState(1);

  const save = async (dataForm: FormValues) => {
    setLoading(true);
    const itens = dataForm.itens.filter((item) => item.amount > 0);
    createMovementCard({
      itens,
      amount: itens.reduce((acc, item) => acc + item.amount, 0),
      cardId,
    })
      .then(() => {
        refetch();
        onClose();
        toast({
          title: "Sucesso",
          description: "Lançamento realizado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar vínculo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      reset();
      setQuantity(1);
    }
  }, [isOpen]);

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPlus />
        <Text ml={2}>Lançamentos</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Lançar movimentos</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack>
                {[...Array(quantity)].map((_, index) => (
                  <HStack mb={2} key={index} w="100%">
                    <InputForm
                      type="text"
                      label="Descrição"
                      name={`itens[${index}].description`}
                      control={control}
                      beatify={true}
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                    <InputNumber
                      label="Valor"
                      name={`itens[${index}].amount`}
                      control={control}
                      rules={{
                        required: "Campo obrigatório",
                        min: { value: 0, message: "Valor mínimo é 0" },
                      }}
                    />
                  </HStack>
                ))}
              </VStack>
              <ButtonGroup>
                <CustomButton
                  leftIcon={<FaMinus />}
                  colorScheme="red"
                  onClick={() => setQuantity(quantity - 1)}
                >
                  Remover item
                </CustomButton>
                <CustomButton
                  colorScheme="brand"
                  leftIcon={<FaPlus />}
                  onClick={() => setQuantity(quantity + 1)}
                >
                  Adicionar item
                </CustomButton>
              </ButtonGroup>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreateCardMovements;
