// Chakra imports
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { findAllWallets, updateWallet } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import TableComponent from "components/TableComponent";
import { queryClient } from "index";
import ModalAddTerminal from "./components/ModalAddWallet";
import { AddIcon, CheckIcon, CloseIcon, CopyIcon } from "@chakra-ui/icons";
import { maskCurrency } from "utils/number";
import SeeQrCode from "./components/SeeQrCode";
import { useMemo } from "react";

export type WalletStatus = "ACTIVE" | "INACTIVE";

export default function WalletsPage() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const toast = useToast();

  const changeStatusWallet = async (id: string, status: WalletStatus) => {
    await updateWallet(id, { status }).then(() => {
      refetch();
      toast({
        title: "Carteira atualizada",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: "Nome",
          accessor: "Customer.name",
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }) => {
            return value === "ACTIVE" ? (
              <Badge color="green"> Ativo</Badge>
            ) : (
              <Badge color="red"> Inativo</Badge>
            );
          },
        },
        {
          Header: "Valor",
          accessor: "amount",
          Cell: ({ value }) => {
            return value ? maskCurrency(value) : "Não informado";
          },
        },
        {
          Header: "Tipo",
          accessor: "type",
          Cell: ({ value }) => {
            return value === "QRES" ? "QRCODE" : "Chave Pix";
          },
        },
        {
          Header: "Chave",
          accessor: "txId",
          Cell: ({ value, row }: any) => {
            return row.original.type === "QRES" ? "Ver Qrcode" : value;
          },
        },
        {
          accessor: "createdAt",
          Header: "Data de criação",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },
        {
          Header: "Ações",
          disableFilters: true,
          disableSortBy: true,
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: any) => (
    <ButtonGroup>
      <Tooltip label="Copiar para a área de transfêrencia" aria-label="Visualizar">
        <IconButton
          aria-label="Copiar valor"
          rounded="md"
          size="sm"
          variant="outline"
          icon={<CopyIcon />}
          onClick={() => {
            navigator.clipboard.writeText(row.payload);
            toast({
              title: "Valor copiado",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }}
        />
      </Tooltip>
      <SeeQrCode data={{ qrCode: row.payload, amount: row.amount }}></SeeQrCode>
      <Tooltip
        label={row.status === "ACTIVE" ? "Desativar" : "Ativar"}
        aria-label="Visualizar"
      >
        <IconButton
          aria-label="Alterar"
          rounded="md"
          size="sm"
          variant="outline"
          icon={row.status === "ACTIVE" ? <CloseIcon /> : <CheckIcon />}
          onClick={() =>
            changeStatusWallet(
              row.id,
              row.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
            )
          }
        />
      </Tooltip>
    </ButtonGroup>
  );

  const refetch = () => {
    queryClient.refetchQueries({
      queryKey: ["findAllWallets"],
      type: "active",
    });
  };

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      {isOpen && (
        <ModalAddTerminal
          onClose={() => {
            onClose();
            refetch();
          }}
          isOpen={isOpen}
        />
      )}
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          queryFn={findAllWallets}
          queryKey="findAllWallets"
          filterable={["customerId"]}
          actions={actions}
          right={
            <ButtonGroup>
              <Button
                onClick={onOpen}
                size="sm"
                rounded="sm"
                variant="outline"
                colorScheme="brand"
              >
                <AddIcon></AddIcon>
                <Text ml={2}>Adicionar</Text>
              </Button>
            </ButtonGroup>
          }
        />
      </SimpleGrid>
    </Box>
  );
}
