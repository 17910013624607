// Chakra imports
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  SimpleGrid,
  Toast,
  useDisclosure,
  useToast,
  IconButton,
  ButtonGroup,
  Badge,
  Tooltip,
} from "@chakra-ui/react";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  changeStatusCustomer,
  deleteCustomerService,
  findAllClients,
} from "services/api.service";
import TableComponent from "components/TableComponent";
import { AddIcon, CheckIcon } from "@chakra-ui/icons";
import { FaEye, FaList, FaTrash } from "react-icons/fa";
import { MdBlock } from "react-icons/md";
import { useHistory } from "react-router-dom";
import RegisterCustomer from "./components/RegisterCustomer";
import { queryClient } from "index";
import { maskDocument } from "utils/formatters";
import { maskCurrency } from "utils/number";
import SendEmails from "../companys/components/SendEmails";
import useAuth, { UserPermissions } from "contexts/useAuth";
import CustomButton from "components/CustomButton";

export default function Customers() {
  const toast = useToast();
  const history = useHistory();
  const { hasPermission } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [user, setUser] = useState<any>(null);
  const cancelRef = useRef();
  const {
    isOpen: isOpenNewCustomer,
    onOpen: onOpenNewCustomer,
    onClose: onCloseNewCustomer,
  } = useDisclosure();

  const hasPermBalance = hasPermission(UserPermissions.CUSTOMERS_BALANCE);
  const writePermission = hasPermission(UserPermissions.CUSTOMERS_WRITE);
  const createPermission = hasPermission(UserPermissions.CUSTOMERS_CREATE);

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "Empresa",
        accessor: "Company.name",
      },
      // {
      //   Header: "Email",
      //   accessor: "email",
      // },
      {
        Header: "Plano",
        accessor: "plan.name",
      },
      {
        Header: "Documento",
        accessor: "document_number",
        Cell: ({ value }: any) => maskDocument(value),
      },
      ...(hasPermBalance
        ? [
            {
              Header: "Saldo",
              accessor: "balance",
              Cell: ({ value }: any) => maskCurrency(+value),
            },
            {
              Header: "Saldo Pendente",
              accessor: "balancePending",
              Cell: ({ value }: any) => maskCurrency(+value),
            },
            {
              Header: "Saldo Bloqueado",
              accessor: "amountLocked",
              Cell: ({ value }: any) => maskCurrency(+value),
            },
          ]
        : []),
      {
        Header: "2FA",
        accessor: "CustomersSecurity.enabled",
        Cell: ({ value }: any) => {
          if (value) return <Badge colorScheme="green">Ativo</Badge>;
          else return <Badge colorScheme="red">Inativo</Badge>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: any) => {
          if (value === 1) return <Badge colorScheme="green">Ativo</Badge>;
          if (value === 2) return <Badge colorScheme="yellow">Pendente</Badge>;
          else return <Badge colorScheme="red">Inativo</Badge>;
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
      },
    ],
    [hasPermBalance]
  );

  const refetch = useCallback(() => {
    queryClient.refetchQueries({
      queryKey: ["findAllClients"],
      type: "active",
    });
  }, []);

  const deleteUser = () => {
    deleteCustomerService(user.id).then(() => {
      onClose();
      Toast({
        title: "Usuário deletado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    });
  };

  const changeStatus = useCallback(
    (id: string, ative: number) => {
      changeStatusCustomer(id, ative).then(() => {
        refetch();
        toast({
          title: "Usuário alterado com sucesso.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      });
    },
    [refetch, toast]
  );

  const actions = useCallback(
    (row: any) => {
      return (
        <ButtonGroup>
          <SendEmails customerId={row.id} />

          <Tooltip label="Ver Cliente">
            <IconButton
              aria-label="Ver Dado"
              rounded="md"
              size="sm"
              variant="outline"
              icon={<FaEye />}
              onClick={() => history.push(`/admin/customer/${row.id}`)}
            />
          </Tooltip>
          <Tooltip label="Ver movimentações">
            <IconButton
              aria-label="Ver movimentações"
              rounded="md"
              size="sm"
              variant="outline"
              icon={<FaList />}
              onClick={() => {
                history.push(`/admin/transactions?customerId=${row.id}`);
              }}
            />
          </Tooltip>
          {[1, 9].includes(row.status) && writePermission && (
            <Tooltip label="Ativar/Desativar Cliente">
              <IconButton
                aria-label="Ativar/Desativar Usuário"
                rounded="md"
                size="sm"
                variant="outline"
                icon={row.status === 1 ? <MdBlock /> : <CheckIcon />}
                onClick={() => {
                  changeStatus(row.id, row.status === 1 ? 9 : 1);
                }}
              />
            </Tooltip>
          )}
          {writePermission && (
            <IconButton
              aria-label="Apagar usuário"
              rounded="md"
              size="sm"
              variant="outline"
              icon={<FaTrash />}
              onClick={() => {
                setUser(row);
                onOpen();
              }}
            />
          )}
        </ButtonGroup>
      );
    },
    [changeStatus, history, onOpen, writePermission]
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Deletar usuário
              </AlertDialogHeader>

              <AlertDialogBody>
                Tem certeza que deseja deletar esse usuário? Essa ação não pode
                ser desfeita.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancelar
                </Button>
                <Button colorScheme="red" onClick={deleteUser} ml={3}>
                  Deletar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        {isOpenNewCustomer && (
          <RegisterCustomer
            isOpen={isOpenNewCustomer}
            onClose={() => {
              onCloseNewCustomer();
              refetch();
            }}
          ></RegisterCustomer>
        )}
        <TableComponent
          columnsData={columns}
          queryFn={findAllClients}
          filterable={["companyId"]}
          queryKey="findAllClients"
          actions={actions}
          right={
            (writePermission || createPermission) && (
              <CustomButton
                isDisabled={false}
                colorScheme="brand"
                variant="solid"
                leftIcon={<AddIcon />}
                onClick={onOpenNewCustomer}
              >
                Adicionar Cliente
              </CustomButton>
            )
          }
        />
      </SimpleGrid>
    </Box>
  );
}
