import { Button, Text } from "@chakra-ui/react";
import React from "react";
import { BiGroup } from "react-icons/bi";
import { useHistory } from "react-router-dom";

const SeeGroups: React.FC<{}> = () => {
  const history = useHistory();
  return (
    <>
      <Button
        onClick={() => {
          history.push("/admin/groupsusers");
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <BiGroup />
        <Text ml={2}>Grupos</Text>
      </Button>
    </>
  );
};

export default SeeGroups;
