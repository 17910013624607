import {
  Button,
  Divider,
  HStack,
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Editor } from "@tinymce/tinymce-react";
import InputForm from "components/InputForm";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaMailBulk } from "react-icons/fa";
import { FiCheck, FiX } from "react-icons/fi";
import { useKeyPressEvent } from "react-use";
import { sendMailToCompanys } from "services/api.service";

interface SendEmailsForm {
  title: string;
}

function SendEmails({
  companyId,
  customerId,
}: {
  customerId?: string;
  companyId?: string;
}) {
  const toast = useToast();
  const { handleSubmit, control, reset } = useForm<SendEmailsForm>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const editorRef = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      reset();
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onSubmit = async (data: SendEmailsForm) => {
    if (!isOpen) return;
    setIsLoading(true);

    const body = editorRef.current.getContent();

    sendMailToCompanys({ companyId, subject: data.title, body, customerId })
      .then(() => {
        toast({
          title: `Emails disparado com sucesso`,
          position: `top`,
          status: `success`,
          duration: 5000,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: err?.response?.data?.message ?? `Erro ao disparar email`,
          position: `top`,
          status: `error`,
          duration: 5000,
        });
      });
  };

  useKeyPressEvent(`Enter`, handleSubmit(onSubmit) as any);

  return (
    <>
      <Tooltip label="Enviar Email">
        <IconButton
          icon={<FaMailBulk />}
          rounded="md"
          size="sm"
          variant="outline"
          onClick={onOpen}
          aria-label="Ativar 2FA"
        />
      </Tooltip>
      <Modal size={`2xl`} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enviar emaill</ModalHeader>
          <VStack p={5} spacing={4}>
            <InputForm
              control={control}
              name={`title`}
              label="Título"
              placeholder="Título do email"
              type="text"
            />
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue=""
              apiKey="e1dz9h9e7jm8yd95x4xbvadeex0oakelvc6qsrtve5t5livm"
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </VStack>
          <Divider my={5} />
          <HStack justifyContent={`END`} m={5}>
            <Button
              leftIcon={<FiX />}
              isLoading={isLoading}
              size={`sm`}
              color={`gray.500`}
              onClick={onClose}
              rounded={`sm`}
            >
              Fechar
            </Button>

            <Button
              leftIcon={<FiCheck />}
              rounded={`sm`}
              size={`sm`}
              isLoading={isLoading}
              colorScheme={`brand`}
              onClick={handleSubmit(onSubmit)}
            >
              Disparar
            </Button>
          </HStack>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SendEmails;
