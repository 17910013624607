import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import FormLabelCustom from "components/FormLabel";
import FormRemoteSelectInput from "components/FormRemoteSelectInput";
import InputNumber from "components/InputNumber";
import { queryClient } from "index";
import React from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa";
import {
  findAllParticipants,
  getAccounts,
  updateWithdrawel,
} from "services/api.service";
import { Withdrawal } from "..";
import { z } from "zod";


export const chaveSchema = z.union([
  z.string().regex(/^\d+$/).length(11),
  z.string().regex(/^\d+$/).length(14),
  z.string().regex(/^\+[1-9]\d\d{1,14}$/),
  z
    .string()
    .regex(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    ),
  z
    .string()
    .regex(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)
    .length(36),
]);

const EditWithdrawel: React.FC<{
  data: Withdrawal;
}> = ({ data }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = React.useState<"DICT" | "MANU">("DICT");
  const [accountId, setAccountId] = React.useState<string | undefined>(
    data.CustomerAccounts?.id
  );

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{
    amount: number;
    description: string;
    fee: string;
    account: string;
    agency: string;
    keyPix: string;
    accountId: { value: string; label: string };
  }>({
    defaultValues: {
      amount: +data.amount,
      description: data.description,
      fee: data.fee,
      account: data.CustomerAccounts?.account,
      agency: data.CustomerAccounts?.agency,
      keyPix: data.CustomerAccounts?.pix,
      accountId: {
        value: data.CustomerAccounts?.id,
        label: ` ${
          data?.CustomerAccounts?.pix ||
          `${data?.CustomerAccounts?.account || ""} - ${
            data?.CustomerAccounts?.agency || ""
          }`
        }`,
      },
    },
  });

  const editWithdrawel = (dataForm: any) => {
    setLoading(true);
    updateWithdrawel(data.id, {
      ...dataForm,
      customerAccountId: accountId,
      ispb: dataForm.ispb?.value,
      type,
    })
      .then(() => {
        toast({
          title: "Sucesso",
          description: `Saque atualizado com sucesso`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
        setLoading(false);
        queryClient.refetchQueries({
          queryKey: ["findAllWithdrawers"],
          type: "active",
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Error",
          description: err.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      {data.status === "PENDING" && (
        <Tooltip label="Editar">
          <IconButton
            aria-label="Ver Saque"
            rounded="md"
            size="sm"
            variant="outline"
            icon={<FaPen />}
            onClick={() => {
              onOpen();
            }}
          />
        </Tooltip>
      )}
      {isOpen && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Editar Saque</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ModalBody>
                  <VStack spacing={5} alignItems="start">
                    <Text fontWeight="semibold">Dados bancários</Text>

                    <FormRemoteSelectInput
                      control={control}
                      label="Conta cadastrada?"
                      onChange={(e) => {
                        setAccountId(e?.value);
                      }}
                      loadDataFn={({ value, cb }) =>
                        getAccounts({
                          filter: value,
                          customerId: data.customerId,
                        }).then((retorno) => {
                          if (retorno?.registers?.length > 0) {
                            cb([
                              {
                                label: "Escolha uma conta",
                                value: "",
                              },
                              ...retorno.registers?.map((d: any) => ({
                                label: d.pix || d.agency + " - " + d.account,
                                value: d.id,
                              })),
                            ]);
                          } else {
                            cb([]);
                          }
                        })
                      }
                      name="accountId"
                    />
                    <FormLabelCustom
                      label="Tipo de transfêrencia"
                      props={undefined}
                      name={""}
                    />
                    <Select
                      onChange={(e) => {
                        setType(e.target.value as any);
                      }}
                      disabled={!!accountId}
                    >
                      <option value="DICT">Chave Pix</option>
                      <option value="MANU">Dados manuais</option>
                    </Select>

                    {type === "DICT" && !accountId && (
                      <>
                        <Text fontWeight="bold">
                          A chave deve pertencer ao mesmo CNPJ do cadastro.
                        </Text>
                        <FormControl isInvalid={!!errors.keyPix?.message}>
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            mb="8px"
                          >
                            Chave Pix (Opcional)
                          </FormLabel>
                          <Input
                            variant="auth"
                            fontSize="sm"
                            name="pix"
                            {...register("keyPix", {
                              required:
                                !!accountId || "Esse campo é obrigatório",
                              validate: (value) => {
                                if (!chaveSchema.safeParse(value).success) {
                                  return "Chave inválida";
                                }
                              },
                            })}
                            ms={{ base: "0px", md: "0px" }}
                            type="text"
                            placeholder="XXXXXXXXXXXXXXXXXXXX"
                            isDisabled={!!accountId}
                          />
                          <FormErrorMessage>
                            {errors.keyPix?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </>
                    )}

                    {type === "MANU" && !accountId && (
                      <>
                        <FormRemoteSelectInput
                          control={control}
                          label="Instituição financeira"
                          required
                          isDisabled={!!accountId}
                          registerOptions={{
                            required: !!accountId || "Esse campo é obrigatório",
                          }}
                          loadDataFn={({ value, cb }) =>
                            findAllParticipants({ filter: value }).then(
                              (retorno) => {
                                if (retorno?.registers?.length > 0) {
                                  cb(
                                    retorno.registers?.map((d: any) => ({
                                      label: d.reducedName,
                                      value: d.ispb,
                                    }))
                                  );
                                } else {
                                  cb([]);
                                }
                              }
                            )
                          }
                          name="ispb"
                        />
                        <FormControl isInvalid={!!errors.agency?.message}>
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            mb="8px"
                          >
                            Agência<Text>*</Text>
                          </FormLabel>
                          <Input
                            isRequired={true}
                            variant="auth"
                            fontSize="sm"
                            maxLength={4}
                            isDisabled={!!accountId}
                            name="agency"
                            {...register("agency", {
                              required: !!accountId,
                              minLength: {
                                message:
                                  "Esse campo deve ter no mínimo 4 caracteres",
                                value: 4,
                              },
                              maxLength: {
                                message:
                                  "Esse campo deve ter no máximo 4 caracteres",
                                value: 4,
                              },
                            })}
                            ms={{ base: "0px", md: "0px" }}
                            type="number"
                            placeholder="0001"
                            required
                          />
                          <FormErrorMessage>
                            {errors.agency?.message}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.account?.message}>
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            mb="8px"
                          >
                            Conta (com dígito)<Text>*</Text>
                          </FormLabel>
                          <Input
                            isRequired={true}
                            variant="auth"
                            isDisabled={!!accountId}
                            fontSize="sm"
                            name="account"
                            {...register("account", {
                              required:
                                !!accountId || "Esse Campo é Obrigatório",
                            })}
                            ms={{ base: "0px", md: "0px" }}
                            type="tel"
                            placeholder="00101"
                          />
                          <FormErrorMessage>
                            {errors.account?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </>
                    )}
                    <InputNumber
                      control={control}
                      placeholder="R$ 0,00"
                      label={"Valor do saque"}
                      name={`amount`}
                      rules={{
                        required: "Campo obrigatório",
                        validate: {
                          positive: (value) =>
                            value > 0 || "O valor deve ser maior que 0",
                        },
                      }}
                    />
                    <FormControl isInvalid={!!errors.description?.message}>
                      <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                        Descrição<Text>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        {...register("description", {
                          maxLength: {
                            value: 100,
                            message: "O limite de caracteres é de 100",
                          },
                        })}
                        ms={{ base: "0px", md: "0px" }}
                        type="tel"
                        placeholder="00101"
                      />
                      <FormErrorMessage>
                        {errors.description?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                </ModalBody>
              </ModalBody>

              <ModalFooter>
                <HStack spacing={4}>
                  <Button
                    colorScheme="red"
                    isLoading={loading}
                    onClick={handleSubmit(editWithdrawel)}
                  >
                    Editar
                  </Button>
                </HStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default EditWithdrawel;
