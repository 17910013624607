import {
  Badge,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import Card from "components/card/Card";
import TableData from "components/TableData";
import useAuth, { UserPermissions } from "contexts/useAuth";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { Column } from "react-table";
import {
  deleteCustomerTaxes,
  findCustomer,
  removeLinkCompany,
  removeLinkMainCustumer,
} from "services/api.service";
import { maskDocument } from "utils/formatters";
import { maskCurrency } from "utils/number";
import Accounts from "./../components/Accounts";
import ChangePasswordCustomer from "./../components/ChangePasswordCustomer";
import RegisterCompany from "./../components/RegisterCompany";
import LinkMainCustomer from "./../components/RegisterMainCustumer";
import RegisterTax from "./../components/RegisterTax";
import EditCustomer from "./EditCustomer";
import TransferCustomerAmount from "./TransferCustomerAmount";
import StatusCustomer from "./StatusCustomer";
import ConfigurationCustomers from "./ConfigurationCustomers";
import AutomaticWithdrawModal from "./AutomaticWithdrawModal";
import EditPlan from "views/admin/taxes/components/EditPlan";
import { OriginTaxesDescription } from "types/Fees";
import FeaturesCustomers from "./FeaturesCustomers";

export default function Customer() {
  const { id } = useParams<{ id: string }>();
  const { data, refetch } = useQuery(["customer", id], () => findCustomer(id));

  const [addTaxUser, setAddTaxUser] = useState(false);
  const [taxUser, setTaxUser] = useState<any>();
  const { userData, hasPermission } = useAuth();
  const hasPermBalance = hasPermission(UserPermissions.CUSTOMERS_BALANCE);
  const hasPermWrite = hasPermission(UserPermissions.CUSTOMERS_WRITE);
  const hasFeeWrite = hasPermission(UserPermissions.FEE_WRITE);

  const history = useHistory();

  const toast = useToast();

  const handleAddTax = () => {
    setAddTaxUser(true);
  };

  const handleEditTax = (tax: any) => {
    setTaxUser(tax);
    setAddTaxUser(true);
  };

  const handleRemoveMain = () => {
    removeLinkMainCustumer(id)
      .then(() => {
        refetch();

        toast({
          title: "Sucesso",
          description: "Conta principal removida com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description: err?.response?.data?.message || "Erro ao remover taxa",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };
  const handleRemoveCompany = () => {
    removeLinkCompany(id)
      .then(() => {
        refetch();

        toast({
          title: "Sucesso",
          description: "Empresa removida com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err: any) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao remover empresa",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };
  const handleRemoveTax = (taxeId: string) => {
    deleteCustomerTaxes(id, taxeId)
      .then(() => {
        refetch();

        toast({
          title: "Sucesso",
          description: "Taxa removida com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description: err?.response?.data?.message || "Erro ao remover taxa",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const columnsMoviments = useMemo(
    () =>
      [
        {
          Header: "Valor",
          accessor: "amount",
          align: "right",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: "Taxas",
          align: "right",
          accessor: "taxes",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: "Valor Líquido",
          align: "right",
          accessor: "amountLiquid",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },

        {
          accessor: "orderAt",
          Header: "Data",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: "Origem",
          accessor: "origin",
          Cell: ({ value }) =>
            OriginTaxesDescription[
              value as keyof typeof OriginTaxesDescription
            ],
        },
        {
          Header: "Taxa",
          accessor: "amount",
          Cell: ({ value, row }: any) => {
            if (row.original?.type === "PERCENTUAL") {
              return `${value} %`;
            }

            return maskCurrency(value);
          },
        },
        {
          Header: "Tipo",
          align: "right",
          accessor: "type",
          Cell: ({ value }) => {
            return value === "FIXED" ? "Fixa" : "Percentual";
          },
        },
        {
          Header: "Liberação em dias",
          accessor: "releaseInDays",
        },
        {
          Header: "Antecipação ativa",
          accessor: "antecipationActive",
          Cell: ({ value }) => {
            return value ? (
              <Badge colorScheme="green">Sim</Badge>
            ) : (
              <Badge colorScheme="yellow">Não</Badge>
            );
          },
        },
        {
          Header: "Taxa de antecipação",
          accessor: "antecipationPercent",
          Cell: ({ value, row }: any) => {
            return `${value} %`;
          },
        },
        {
          Header: "Valor mínimo",
          accessor: "minFixed",
          align: "right",
          Cell: ({ value }) => {
            return +value ? maskCurrency(value) : "---";
          },
        },
        {
          Header: "Valor máximo",
          accessor: "maxFixed",
          align: "right",
          Cell: ({ value }) => {
            return +value ? maskCurrency(value) : "---";
          },
        },
        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const handleMoviment = (id: string) => {
    history.push(`/admin/movement/${id}`);
  };

  return (
    <>
      {addTaxUser && (
        <RegisterTax
          customerId={id}
          dataTax={taxUser}
          isOpen={addTaxUser}
          onClose={() => {
            setAddTaxUser(false);
            setTaxUser(undefined);
            refetch();
          }}
        />
      )}
      <Card
        flexDirection="column"
        w="100%"
        px={5}
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <StatusCustomer
          document={data?.document_number}
          id={data?.id}
          refetch={refetch}
          status={data?.status}
        />
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} gap={3}>
          <VStack alignItems="start">
            <Text color="gray.500">Nome</Text>
            <Text>{data?.name}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Email</Text>
            <Text>{data?.email}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Documento</Text>
            <Text>{maskDocument(data?.document_number)}</Text>
          </VStack>
          {hasPermBalance && (
            <>
              <VStack alignItems="start">
                <Text color="gray.500">Saldo</Text>
                <Text>{maskCurrency(+data?.balance)}</Text>
                {hasPermission(UserPermissions.CUSTOMERS_TRANSFER) && (
                  <TransferCustomerAmount
                    customerId={data?.id}
                    type={`balance`}
                    maxAmount={+data?.balance}
                    refetch={refetch}
                  />
                )}
              </VStack>
              <VStack alignItems="start">
                <Text color="gray.500">Saldo Pendente</Text>
                <Text>{maskCurrency(+data?.balancePending)}</Text>
              </VStack>
              <VStack alignItems="start">
                <Text color="gray.500">Saldo Bloqueado</Text>
                <Text>{maskCurrency(+data?.amountLocked)}</Text>
              </VStack>
            </>
          )}
          <VStack alignItems="start">
            <Text color="gray.500">Plano</Text>
            <Text>{data?.plan?.name ?? "---"}</Text>
            {data?.plan?.id && (
              <HStack>
                <EditPlan id={data?.plan?.id} readOnly />
              </HStack>
            )}
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Conta Principal</Text>
            <Text>{data?.MainCustomer?.name || "---"}</Text>
            <ButtonGroup>
              {hasPermWrite && <LinkMainCustomer customerId={data?.id} />}
              {data?.MainCustomer?.id && (
                <>
                  <Button
                    colorScheme="brand"
                    onClick={() =>
                      history.push(`/admin/customer/${data?.MainCustomer?.id}`)
                    }
                    size="sm"
                    variant="link"
                  >
                    Ver
                  </Button>
                  {hasPermWrite && (
                    <Button
                      onClick={handleRemoveMain}
                      colorScheme="red"
                      size="sm"
                      variant="link"
                    >
                      Remover
                    </Button>
                  )}
                </>
              )}
            </ButtonGroup>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Empresa</Text>
            <Text>{data?.Company?.name || "---"}</Text>
            {hasPermWrite && !userData.isCustomerAdmin && (
              <ButtonGroup>
                <RegisterCompany customerId={data?.id} id={data?.Company?.id} />
                {data?.Company?.id && (
                  <>
                    <Button
                      onClick={handleRemoveCompany}
                      colorScheme="red"
                      size="sm"
                      variant="link"
                    >
                      Remover
                    </Button>
                  </>
                )}
              </ButtonGroup>
            )}
          </VStack>{" "}
          <VStack alignItems="start">
            <Text color="gray.500">2FA</Text>
            <Text>
              {data?.CustomersSecurity?.enabled ? (
                <Badge colorScheme="green">Ativo</Badge>
              ) : (
                <Badge colorScheme="red">Inativo</Badge>
              )}
            </Text>
          </VStack>
          {data?.CustomerAutomaticWithdrawel && (
            <>
              <VStack alignItems="start">
                <Text color="gray.500">Limite diário automático</Text>
                <Text>
                  {maskCurrency(
                    data?.CustomerAutomaticWithdrawel?.maxAutomaticWithdrawal
                  )}
                </Text>
              </VStack>
              <VStack alignItems="start">
                <Text color="gray.500">Percentual diário automatico</Text>
                <Text>
                  {+data?.CustomerAutomaticWithdrawel?.percent * 100}%
                </Text>
              </VStack>
            </>
          )}
        </SimpleGrid>

        <ButtonGroup mt={5}>
          {hasPermWrite && <EditCustomer data={data} refetch={refetch} />}
          {hasPermWrite && (
            <>
              <ConfigurationCustomers
                customerId={data?.id}
                config={data?.configurations}
                refetch={refetch}
              />
              <FeaturesCustomers
                customerId={data?.id}
                features={data?.features}
                refetch={refetch}
              />
            </>
          )}
          {hasPermWrite && (
            <AutomaticWithdrawModal
              customerId={data?.id}
              config={{
                ...data?.CustomerAutomaticWithdrawel,
                accountId: {
                  value: data?.CustomerAutomaticWithdrawel?.customerAccountId,
                  label: "Conta",
                },
                maxAutomaticWithdrawal:
                  data?.CustomerAutomaticWithdrawel?.maxAutomaticWithdrawal,
                percent: +data?.CustomerAutomaticWithdrawel?.percent * 100,
              }}
              refetch={refetch}
            />
          )}
          {hasPermWrite && <ChangePasswordCustomer customerId={data?.id} />}
        </ButtonGroup>
        {userData.isCustomerAdmin || !hasFeeWrite || data?.plan?.id ? null : (
          <TableData
            title="Taxas do cliente"
            actions={(row: any) => (
              <ButtonGroup>
                <IconButton
                  onClick={() => handleEditTax(row)}
                  variant="outline"
                  rounded="md"
                  size="sm"
                  aria-label="Editar"
                  icon={<FaEdit />}
                />
                <IconButton
                  onClick={() => handleRemoveTax(row?.id)}
                  variant="outline"
                  rounded="md"
                  size="sm"
                  aria-label="Remover"
                  icon={<FaTrash />}
                />
              </ButtonGroup>
            )}
            columnsData={columns}
            tableData={data?.CustomerTaxes || []}
            pageEnable={true}
            rightActions={
              <Button
                onClick={() => handleAddTax()}
                variant="outline"
                size="sm"
                colorScheme="gray"
              >
                Adicionar Taxa
              </Button>
            }
          />
        )}
        <TableData
          title="Últimas 5 transações"
          actions={(row: any) => (
            <>
              <IconButton
                onClick={() => handleMoviment(row?.id)}
                variant="outline"
                rounded="sm"
                size="xs"
                aria-label="Ver movimentação"
                icon={<FaEye />}
              />
            </>
          )}
          columnsData={columnsMoviments}
          tableData={data?.Movements || []}
        />
        <Accounts
          customerId={id}
          accounts={data?.CustomerAccounts}
          refetch={refetch}
        />
      </Card>
    </>
  );
}
