import { DeleteIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  VStack,
  useToast,
  IconButton,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { deleteGroup } from "services/api.service";

const ModalDeleteGroup: FC<{
  id: number;
  refetch: () => void;
}> = ({ id,refetch }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    setLoading(true);
    deleteGroup(id)
      .then(() => {
        onClose();
        refetch();
        toast({
          title: "Sucesso",
          description: "Registro apagado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar vínculo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Tooltip label="Excluir grupo" aria-label="Visualizar">
        <IconButton
          aria-label="Excluir grupo"
          rounded="md"
          size="sm"
          variant="outline"
          icon={<DeleteIcon />}
          onClick={() => {
            onOpen();
          }}
        />
      </Tooltip>
      {isOpen && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Deletar Grupo</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack spacing={4}>
                  <Text>Tem certeza que deseja deletar o Grupo </Text>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <HStack spacing={4}>
                  <Button
                    colorScheme="red"
                    isLoading={loading}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    colorScheme="green"
                    isLoading={loading}
                    onClick={handleDelete}
                  >
                    Deletar
                  </Button>
                </HStack>
              </ModalFooter>
              ;
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default ModalDeleteGroup;
