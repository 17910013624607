// Chakra imports
import {
  Box,
  Button,
  ButtonGroup,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { findAllUsersGroups } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import TableComponent from "components/TableComponent";
import { queryClient } from "index";
import ModalAddGroup from "./components/ModalAddGroup";
import { AddIcon } from "@chakra-ui/icons";
import SeePermissions from "./components/SeePermissions";
import { useMemo } from "react";
import useAuth, { UserPermissions } from "contexts/useAuth";
import ModalDeleteGroup from "./components/ModalDeleteGroup";

export interface Group {
  id: number;
  name: string;
  type: string;
  createdAt: string;
  GroupsPermissions: GroupsPermission[];
}

export interface GroupsPermission {
  id: number;
  groupId: number;
  permissionId: number;
  createdAt: string;
  updatedAt: string;
  Permissions: Permissions;
}

export interface Permissions {
  id: number;
  permission: UserPermissions;
  description: any;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export default function UserGroups() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { hasPermission } = useAuth();
  const writePermission = hasPermission(UserPermissions.USERS_WRITE);

  const columns = useMemo(
    () =>
      [
        {
          Header: "Nome",
          accessor: "name",
        },
        {
          Header: "Tipo",
          accessor: "type",
        },
        {
          accessor: "createdAt",
          Header: "Data de criação",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },
        {
          Header: "Ações",
          disableFilters: true,
          disableSortBy: true,
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: Group) => (
    <ButtonGroup>
      <SeePermissions
        id={row.id}
        refetch={refetch}
        disabled={!writePermission}
        permissions={row.GroupsPermissions.map(
          (permission) => permission.Permissions.permission
        )}
      ></SeePermissions>
      {writePermission && <ModalDeleteGroup id={row.id} refetch={refetch} />}
    </ButtonGroup>
  );

  const refetch = () => {
    queryClient.refetchQueries({
      queryKey: ["findAllUsersGroups"],
      type: "active",
    });
  };

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      {isOpen && (
        <ModalAddGroup
          onClose={() => {
            onClose();
            refetch();
          }}
          isOpen={isOpen}
        />
      )}
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          queryFn={findAllUsersGroups}
          queryKey="findAllUsersGroups"
          actions={actions}
          right={
            <ButtonGroup>
              {writePermission && (
                <Button
                  onClick={onOpen}
                  size="sm"
                  rounded="sm"
                  variant="outline"
                  colorScheme="brand"
                >
                  <AddIcon></AddIcon>
                  <Text ml={2}>Adicionar</Text>
                </Button>
              )}
            </ButtonGroup>
          }
        />
      </SimpleGrid>
    </Box>
  );
}
