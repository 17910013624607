import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import FormLabelCustom from "components/FormLabel";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import React from "react";
import { useForm } from "react-hook-form";
import {
  createAccount,
  findAllParticipants,
  updateAccount,
} from "services/api.service";
import { chaveSchema } from "views/admin/withdrawals/components/EditWithdrawel";
export interface CreateAccountCustomerForm {
  id?: string;
  bankIspb: SelectOptionType;
  agency: string;
  account: string;
  pix?: string;
}

const CreateAccountCustomer: React.FC<{
  isOpen: boolean;
  data?: CreateAccountCustomerForm;
  customerId: string;
  onClose: () => void;
}> = ({ data, onClose, isOpen, customerId }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState<"DICT" | "MANU">("DICT");

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{
    id?: string;
    bankIspb: SelectOptionType;
    agency: string;
    account: string;
    pix?: string;
  }>({
    defaultValues: {
      account: data?.account,
      agency: data?.agency,
      bankIspb: data?.bankIspb,
      id: data?.id,
      pix: data?.pix,
    },
  });

  const onSubmit = (dataRequest: CreateAccountCustomerForm) => {
    setLoading(true);
    let request = createAccount(customerId, {
      ...dataRequest,
      bankIspb: dataRequest.bankIspb?.value,
      type: type,
    });

    if (data?.id) {
      request = updateAccount(customerId, data?.id, {
        ...dataRequest,
        bankIspb: dataRequest.bankIspb?.value,
        type: type,
      });
    }

    request
      .then(() => {
        toast({
          title: "Succeso",
          description: `Conta Bancária ${
            data?.id ? "Atualizada" : "Criada"
          } com Sucesso`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Error",
          description: err.response.data?.message,
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Criar Conta Bancária</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={5} alignItems="start">
              <Text fontWeight="semibold">Dados bancários</Text>
              <FormLabelCustom
                label="Tipo de transfêrencia"
                props={undefined}
                name={""}
              />
              <Select
                onChange={(e) => {
                  setType(e.target.value as any);
                }}
              >
                <option value="DICT">Chave Pix</option>
                <option value="MANU">Dados manuais</option>
              </Select>

              {type === "DICT" && (
                <>
                  <Text fontWeight="bold">
                    A chave deve pertencer ao mesmo CNPJ do cadastro.
                  </Text>
                  <FormControl isInvalid={!!errors.pix?.message}>
                    <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                      Chave Pix (Opcional)
                    </FormLabel>
                    <Input
                      variant="auth"
                      fontSize="sm"
                      name="pix"
                      {...register("pix", {
                        required: "Esse campo é obrigatório",
                        validate: (value) => {
                          if (!chaveSchema.safeParse(value).success) {
                            return "Chave inválida";
                          }
                        },
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      placeholder="XXXXXXXXXXXXXXXXXXXX"
                    />
                    <FormErrorMessage>
                      {errors.pix?.message}
                    </FormErrorMessage>
                  </FormControl>
                </>
              )}

              {type === "MANU" && (
                <>
                  <FormRemoteSelectInput
                    control={control}
                    label="Instituição financeira"
                    required
                    registerOptions={{
                      required: "Esse campo é obrigatório",
                    }}
                    loadDataFn={({ value, cb }) =>
                      findAllParticipants({ filter: value }).then((retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: d.reducedName,
                              value: d.ispb,
                            }))
                          );
                        } else {
                          cb([]);
                        }
                      })
                    }
                    name="ispb"
                  />
                  <FormControl isInvalid={!!errors.agency?.message}>
                    <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                      Agência<Text>*</Text>
                    </FormLabel>
                    <Input
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      maxLength={4}
                      name="agency"
                      {...register("agency", {
                        minLength: {
                          message: "Esse campo deve ter no mínimo 4 caracteres",
                          value: 4,
                        },
                        maxLength: {
                          message: "Esse campo deve ter no máximo 4 caracteres",
                          value: 4,
                        },
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      type="number"
                      placeholder="0001"
                      required
                    />
                    <FormErrorMessage>
                      {errors.agency?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.account?.message}>
                    <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                      Conta (com dígito)<Text>*</Text>
                    </FormLabel>
                    <Input
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      name="account"
                      {...register("account", {
                        required: "Esse Campo é Obrigatório",
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      type="tel"
                      placeholder="00101"
                    />
                    <FormErrorMessage>
                      {errors.account?.message}
                    </FormErrorMessage>
                  </FormControl>
                </>
              )}
            </VStack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="brand"
            isLoading={loading}
            type="submit"
            onClick={() => handleSubmit(onSubmit)()}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateAccountCustomer;
