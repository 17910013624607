import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Button,
  useToast,
  VStack,
  Select,
} from "@chakra-ui/react";
import InputForm from "components/InputForm";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { createUserGroups } from "services/api.service";

const ModalAddGroup: FC<{
  onClose: () => void;
  isOpen: boolean;
}> = ({ onClose, isOpen }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, register, setValue } = useForm<{
    type: "USER";
    name: string;
  }>();

  const save = async (dataForm: { type: "USER"; name: string }) => {
    setLoading(true);
    createUserGroups({
      ...dataForm,
    })
      .then(() => {
        onClose();
        toast({
          title: "Sucesso",
          description: "Grupo adicionada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar grupo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar Grupo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={3}>
            <Select
              placeholder="Selecione o tipo."
              {...register("type", { required: true })}
              onChange={(e) => {
                setValue("type", e.target.value as any);
              }}
            >
              <option value="USER">Usuários</option>
            </Select>
            <InputForm
              control={control}
              label="Nome"
              name="name"
              placeholder="Nome"
              required
              type="text"
              rules={{
                required: "Campo obrigatório",
              }}
            ></InputForm>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              colorScheme="red"
              isLoading={loading}
              onClick={() => {
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              isLoading={loading}
              onClick={handleSubmit(save)}
            >
              Adicionar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAddGroup;
