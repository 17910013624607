import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputNumber from "components/InputNumber";
import PercentageNumberInput from "components/PercentageInput";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaSortAmountDown } from "react-icons/fa";
import { automaticWithdraw, getAccounts } from "services/api.service";

export interface CustomerConfigurations {
  percent: number;
  accountId: SelectOptionType;
  maxAutomaticWithdrawal: string;
}

const AutomaticWithdrawModal: React.FC<{
  customerId: string;
  config?: CustomerConfigurations;
  refetch: () => {};
}> = ({ customerId, config, refetch }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset, setValue } =
    useForm<CustomerConfigurations>();
  const save = async ({
    maxAutomaticWithdrawal,
    accountId,
    percent,
  }: CustomerConfigurations) => {
    setLoading(true);
    automaticWithdraw(customerId, {
      maxAutomaticWithdrawal: parseFloat(maxAutomaticWithdrawal),
      accountId: accountId.value,
      percent: percent / 100,
      active: true,
    })
      .then(() => {
        onClose();
        refetch();
        toast({
          title: "Sucesso",
          description: "Cliente atualizado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao atualizar cliente",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (config) {
      setValue("percent", config.percent);
      setValue("maxAutomaticWithdrawal", config.maxAutomaticWithdrawal);
      setValue("accountId", config.accountId);
    }
  }, [isOpen, config, setValue]);

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          reset();
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaSortAmountDown />
        <Text ml={2}>Saques Diários</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Editar Atributos</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <InputNumber
                  label="Valor máximo de saque automático"
                  control={control}
                  name="maxAutomaticWithdrawal"
                  rules={{ required: true }}
                />
                <PercentageNumberInput
                  label="Percentual de saque automático"
                  control={control}
                  name="percent"
                  rules={{ required: true }}
                />
                <FormRemoteSelectInput
                  control={control}
                  label="Conta cadastrada?"
                  loadDataFn={({ value, cb }) =>
                    getAccounts({
                      filter: value,
                      customerId: customerId,
                    }).then((retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb([
                          {
                            label: "Escolha uma conta",
                            value: "",
                          },
                          ...retorno.registers?.map((d: any) => ({
                            label: d.pix || d.agency + " - " + d.account,
                            value: d.id,
                          })),
                        ]);
                      } else {
                        cb([]);
                      }
                    })
                  }
                  name="accountId"
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Salvar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AutomaticWithdrawModal;
