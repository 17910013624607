// Chakra imports
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { useMemo } from "react";
import { findAllPlans } from "services/api.service";
import { Column } from "react-table";
import TableComponent from "components/TableComponent";
import CreatePlan from "./components/CreateTax";
import { AddIcon } from "@chakra-ui/icons";
import EditPlan from "./components/EditPlan";
import useAuth, { UserPermissions } from "contexts/useAuth";
import CopyPlan from "./components/CopyPlan";
import ModalDeletePlan from "./components/ModalDeletePlan";
import { queryClient } from "index";
import { Plan } from "./components/Plan.types";

export default function Plans() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { hasPermission } = useAuth();

  const writePerm = hasPermission(UserPermissions.PLAN_WRITE);

  const columns = useMemo(
    () =>
      [
        {
          Header: "Nome",
          accessor: "name",
        },
        {
          Header: "Descrição",
          accessor: "description",
        },
        {
          Header: "Status",
          accessor: "active",
          Cell: ({ value }) =>
            value ? (
              <Badge color={"green"}>Ativo</Badge>
            ) : (
              <Badge color={"red"}>Inativo</Badge>
            ),
        },
        {
          Header: "Empresa?",
          accessor: "company.name",
        },
        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: Plan) => (
    <ButtonGroup>
      <EditPlan id={row.id} readOnly={!writePerm} />
      {writePerm && <CopyPlan id={row.id} />}
      {writePerm && (
        <ModalDeletePlan
          id={row.id}
          refetch={() => {
            queryClient.refetchQueries(["findAllPlans"]);
          }}
        />
      )}
    </ButtonGroup>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {isOpen && (
          <CreatePlan
            isOpen={isOpen}
            onClose={() => {
              onClose();
            }}
          ></CreatePlan>
        )}
        <TableComponent
          columnsData={columns}
          queryFn={findAllPlans}
          queryKey="findAllPlans"
          actions={actions}
          filterable={["companyId"]}
          right={
            !writePerm ? null : (
              <Button
                onClick={onOpen}
                size="sm"
                rounded="sm"
                variant="outline"
                colorScheme="brand"
              >
                <AddIcon></AddIcon>
                <Text ml={2}>Adicionar</Text>
              </Button>
            )
          }
        />
      </SimpleGrid>
    </Box>
  );
}
