import {
  Box,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import React from "react";
import { BsEye } from "react-icons/bs";
import { FiCopy } from "react-icons/fi";
import { QRCode } from "react-qrcode-logo";
import { useCopyToClipboard } from "react-use";
import { maskCurrency } from "utils/number";
import { getVariable } from "whitelabel";

const SeeQrCode: React.FC<{
  data: {
    qrCode: string;
    amount?: number;
  };
}> = ({ data: dataWithdraw }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <>
      <Tooltip label="Ver Pix Copia e Cola" placement="top" hasArrow>
        <IconButton
          aria-label="Ver qrcode"
          rounded="md"
          size="sm"
          variant="outline"
          icon={<BsEye />}
          onClick={() => {
            onOpen();
          }}
        />
      </Tooltip>
      {isOpen && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Verificar Pix Copia e Cola</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack alignItems={`center`} spacing={5}>
                  {dataWithdraw.amount && (
                    <Text>
                      <strong>Valor:</strong>{" "}
                      {maskCurrency(dataWithdraw.amount)}
                    </Text>
                  )}
                  <QRCode
                    value={dataWithdraw.qrCode}
                    logoImage={getVariable(`logo`)}
                    size={256}
                    removeQrCodeBehindLogo
                  />
                  <Box textAlign={`center`}>
                    <HStack justifyContent={`center`} spacing={1}>
                      <Tooltip label="Copiar código" placement="top" hasArrow>
                        <CustomButton
                          leftIcon={<FiCopy />}
                          onClick={() => {
                            copyToClipboard(dataWithdraw.qrCode as string);
                            toast({
                              title: `Código copiado`,
                              position: `top`,
                              status: `success`,
                              duration: 1500,
                              isClosable: true,
                            });
                          }}
                        >
                          Copiar código
                        </CustomButton>
                      </Tooltip>
                    </HStack>
                  </Box>
                </VStack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default SeeQrCode;
