import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import { queryClient } from "index";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa";
import { findAllClients, linkMainCustumer } from "services/api.service";

const LinkMainCustomer: FC<{ customerId: string }> = ({ customerId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit } = useForm<{
    customer: SelectOptionType;
    name: string;
    number: string;
  }>();

  const save = async (dataForm: {
    customer: SelectOptionType;
    name: string;
    number: string;
  }) => {
    setLoading(true);
    linkMainCustumer(customerId, dataForm?.customer?.value)
      .then(() => {
        queryClient.refetchQueries({
          queryKey: ["customer"],
          type: "active",
        });
        onClose();
        toast({
          title: "Sucesso",
          description: "Cliente principal adicionada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar vínculo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPen />
        <Text ml={2}>Vincular</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Cliente Principal</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormRemoteSelectInput
                control={control}
                label="Cliente"
                loadDataFn={({ value, cb }) =>
                  findAllClients({ filter: value }).then((retorno) => {
                    if (retorno?.registers?.length > 0) {
                      cb(
                        retorno.registers?.map((d: any) => ({
                          label: d.name,
                          value: d.id,
                        }))
                      );
                    } else {
                      cb([]);
                    }
                  })
                }
                name="customer"
              />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default LinkMainCustomer;
