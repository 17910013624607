import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  IconButton,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { FaTrash } from "react-icons/fa";
import { deleteCustomerAccount } from "services/api.service";

const AlertDeleteAccount: React.FC<{ id: string; refetch: () => void }> = ({
  id,
  refetch,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const toast = useToast();

  const handleDelete = () => {
    deleteCustomerAccount(id)
      .then(() => {
        toast({
          title: "Sucesso",
          description: "Conta bancária excluída com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        refetch();
        onClose();
      })
      .catch(() => {
        toast({
          title: "Erro",
          description: "Erro ao excluir conta bancária",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Tooltip label="Remover">
        <IconButton
          onClick={() => onOpen()}
          variant="outline"
          rounded="sm"
          size="sm"
          aria-label="Remover Conta"
          icon={<FaTrash />}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Excluir conta bancária
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja excluir a conta bancária?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Deletar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AlertDeleteAccount;
