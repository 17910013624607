import {
  Box,
  Button,
  Divider,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import InputForm from "components/InputForm";
import { createContext, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { FiCheck, FiX } from "react-icons/fi";
import { useKeyPressEvent } from "react-use";

interface TwoFaCheckContextParams {
  requestTwoFa(): Promise<string>;
}

export const TwoFaCheckContext = createContext<TwoFaCheckContextParams>({} as any);

export const useTwoFaHelper = () => useContext(TwoFaCheckContext);

interface Enable2FAForm {
  code2FA: string;
}

export const TwoFaHelperProvider = ({ children }) => {
  const [promiseFn, setPromiseFn] = useState<any>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<Enable2FAForm>();

  const requestTwoFa = (): Promise<string> => {
    reset();
    return new Promise<string>(function handleConfirmation(resolve, reject) {
      setPromiseFn({ resolve, reject });
      onOpen();
    });
  };

  const handleOK = (data: Enable2FAForm) => {
    promiseFn?.resolve(data.code2FA);
    onClose();
  };

  const handleCancel = () => {
    promiseFn?.resolve(``);
    onClose();
  };

  useKeyPressEvent(`Enter`, handleSubmit(handleOK) as any);

  return (
    <TwoFaCheckContext.Provider value={{ requestTwoFa }}>
      {children}
      <Modal size={`md`} isOpen={isOpen} onClose={handleCancel}>
        <ModalOverlay />
        <ModalContent>
          <Box p={5}>
            <Text textAlign={`center`}>AUTENTICAÇÃO DE DOIS FATORES (2FA)</Text>
            <Divider my={5} />
            <Text fontSize={`sm`} mb={5}>
              Informe o código de autenticação gerado pelo aplicativo
            </Text>
            <InputForm
              label="Código de autenticação"
              type="text"
              control={control}
              name="code2FA"
              placeholder="Código de autenticação"
              labelProps={{
                fontSize: 10,
                textColor: `gray.500`,
              }}
              rules={{
                required: `Informe o código de autenticação`,
                minLength: {
                  value: 6,
                  message: `Necessário 6 caracteres`,
                },
              }}
            />

            <Divider my={5} />

            <HStack justifyContent={`end`}>
              <Button
                leftIcon={<FiX />}
                size={`sm`}
                onClick={handleCancel}
                rounded={`sm`}
              >
                Cancelar
              </Button>
              <Button
                leftIcon={<FiCheck />}
                rounded={`sm`}
                size={`sm`}
                colorScheme={`brand`}
                onClick={handleSubmit(handleOK)}
              >
                Confirmar
              </Button>
            </HStack>
          </Box>
        </ModalContent>
      </Modal>
    </TwoFaCheckContext.Provider>
  );
};
