// Chakra imports
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import InputLogin from "./InputLogin";
import { getVariable } from "whitelabel";

function SignIn() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  return (
    <DefaultAuth
      illustrationBackground={getVariable(`banner`)}
      image={getVariable(`banner`)}
    >
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "30px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "0", md: "4vh" }}
          flexDirection="column"
        >
          <Box
            w="full"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Image src={getVariable("logo")} width="200px" />
            <Heading color={textColor} fontSize="24px" mb="10px">
              Entrar como Administrador
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Entre aqui para acessar sua conta
            </Text>
          </Box>
          <InputLogin />
        </Flex>
      </GoogleReCaptchaProvider>
    </DefaultAuth>
  );
}

export default SignIn;
