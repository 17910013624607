import { useToast, Flex, VStack, Button } from "@chakra-ui/react";
import InputForm from "components/InputForm";
import useAuth from "contexts/useAuth";
import React, { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

const InputLogin = () => {
  const { control, handleSubmit, setValue } = useForm<{
    email: string;
    password: string;
    twoFa?: string;
  }>();

  const toast = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();

  const handleLogin = async (dataLogin: {
    email: string;
    password: string;
    twoFa?: string;
  }) => {
    const captchaKey = await handleReCaptchaVerify("USER_LOGIN");

    if (dataLogin.password.length < 6) {
      toast({
        title: "Error",
        description: "A senha deve ter no mínimo 8 caracteres",
        status: "error",
        duration: 5000,
        position: "top",
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    await signIn({
      username: dataLogin.email,
      twoFa: dataLogin.twoFa,
      password: dataLogin.password,
      captchaKey,
      history,
    })
      .then(() => {
        setLoading(false);
        localStorage.setItem("email_credential_admin", dataLogin.email);
      })
      .catch((error) => {
        setLoading(false);
        toast({
          title: "Error",
          description:
            error?.response?.data?.message || "Erro ao realizar login",
          status: "error",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
      });
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(
    async (type = "USER_LOGIN") => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      return await executeRecaptcha(type);
      // Do whatever you want with the token
    },
    [executeRecaptcha]
  );

  useEffect(() => {
    const savedLogin = localStorage.getItem("email_credential_admin");

    if (savedLogin) {
      setValue("email", savedLogin);
    }
  }, [setValue]);

  return (
    <Flex
      zIndex="2"
      direction="column"
      w={{ base: "100%", md: "420px" }}
      maxW="100%"
      background="transparent"
      borderRadius="15px"
      mx={{ base: "auto", lg: "unset" }}
      me="auto"
      mb={{ base: "20px", md: "auto" }}
    >
      <form onSubmit={handleSubmit(handleLogin)}>
        <VStack spacing={5}>
          <InputForm
            control={control}
            label="E-mail"
            name="email"
            autoFocus
            placeholder="Digite seu e-mail..."
            beatify
            type="email"
          />
          <InputForm
            control={control}
            label="Senha"
            placeholder="Digite sua senha..."
            name="password"
            beatify
            type="password"
          />
          <InputForm
            control={control}
            label="2FA"
            placeholder="Caso ativo, digite o 2fa"
            name="twoFa"
            beatify
            type="text"
          />
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            type="submit"
            isLoading={loading}
          >
            Entrar
          </Button>
        </VStack>
      </form>
    </Flex>
  );
};

export default InputLogin;
