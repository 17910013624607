import { Box, ButtonGroup, Heading } from "@chakra-ui/react";
import Card from "components/card/Card";
import useAuth from "contexts/useAuth";
import ChangePasswordCustomer from "./components/ChangePasswordCustomer";
import Enable2FA from "./Enable2FA";

export default function Settings() {
  const { userData } = useAuth();

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px={5}
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Heading mb={2} fontSize={20}>
          Olá, {userData?.name}
        </Heading>
        <Heading fontSize={20} fontWeight="semibold">
          Configurações
        </Heading>
        <ButtonGroup mt={5}>
          <ChangePasswordCustomer customerId={userData?.id} />

          {!userData.twoFa && <Enable2FA />}
        </ButtonGroup>
      </Card>
    </Box>
  );
}
