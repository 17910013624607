import { Box, Flex, Image, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { forwardRef } from "react";
import { maskCurrency } from "utils/number";
import { DateTime } from "luxon";
import { HSeparator } from "components/separator/Separator";
import { InsuranceStatusRender, InsurancesStatus } from "..";
import { getVariable } from "whitelabel";

export interface InsuranceData {
  createdAt: string;
  id: string;
  startDate: string;
  hash: string;
  endDate: string;
  price: string;
  holder: Holder;
  model: Model;
  status: InsurancesStatus;
  customer: Customer;
}

export interface Holder {
  name: string;
  id: string;
  document: string;
}

export interface Model {
  name: string;
  price: string;
  pricePerDay: string;
}

export interface Customer {
  name: string;
  id: string;
}

const ComprovanteImpressao = ({ data: printData }, ref) => {
  if (!printData) {
    return <></>;
  }

  const dataInsurance = printData as InsuranceData;

  return (
    <Box maxW={800} w={`100%`} ref={ref} p={10}>
      <Box>
        <Flex flexDirection={`column`} w={`100%`} alignItems={`center`}>
          <VStack spacing={3} pt={`10px`} mb="5px">
            <Image
              cursor="pointer"
              alt=""
              src={getVariable(`logo`)}
              maxWidth={64}
              maxHeight={20}
            />
            <HSeparator />
          </VStack>
          <Text
            color={`brand.500`}
            fontSize={{ sm: `lg`, md: `xl`, lg: `lg` }}
            fontWeight="bold"
            alignItems={`center`}
          >
            Comprovante de Seguro
          </Text>
          {dataInsurance?.createdAt && (
            <Text fontWeight={700} fontSize={12} mt={3} color={`#666`}>
              {DateTime.fromISO(dataInsurance.createdAt).toFormat(
                "dd/MM/yyyy HH:mm:ss"
              )}
            </Text>
          )}
        </Flex>
        <SimpleGrid
          w={`100%`}
          my={10}
          py={10}
          borderY={`1px solid #eee`}
          columns={1}
          spacing={4}
        >
          <SimpleGrid columns={2} spacing={2}>
            <Box>
              <Text fontSize={10} color={`#999`}>
                Segurado
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {dataInsurance.holder.name}
              </Text>
            </Box>
            <Box>
              <Text fontSize={10} color={`#999`}>
                Documento
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {dataInsurance.holder.document}
              </Text>
            </Box>
            <Box>
              <Text fontSize={10} color={`#999`}>
                Status
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                <InsuranceStatusRender status={dataInsurance.status} />
              </Text>
            </Box>
            <Box>
              <Text fontSize={10} color={`#999`}>
                Valor pago
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {maskCurrency(Math.abs(Number(dataInsurance?.price)))}
              </Text>
            </Box>
            <Box>
              <Text fontSize={10} color={`#999`}>
                Data de Início
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {DateTime.fromISO(dataInsurance.startDate).toFormat(
                  "dd/MM/yyyy"
                )}
              </Text>
            </Box>{" "}
            <Box>
              <Text fontSize={10} color={`#999`}>
                Data de Fim
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {DateTime.fromISO(dataInsurance.endDate).toFormat("dd/MM/yyyy")}
              </Text>
            </Box>
            <Box>
              <Text fontSize={10} color={`#999`}>
                Autenticação
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {dataInsurance.hash}
              </Text>
            </Box>
            <Box>
              <Text fontSize={10} color={`#999`}>
                Valor de Cobertura
              </Text>
              <Text color={`#333`} fontWeight={700} fontSize={14}>
                {maskCurrency(Math.abs(Number(dataInsurance?.model.price)))}
              </Text>
            </Box>
          </SimpleGrid>
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default forwardRef(ComprovanteImpressao);
