import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

export default function TableData(props: {
  columnsData: any[];
  tableData: any[];
  actions: any;
  title: string;
  rightActions?: any;
  pageEnable?: boolean;
}) {
  const {
    columnsData,
    tableData,
    pageEnable = false,
    actions,
    title,
    rightActions,
  } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable<{ id: string }>(
    {
      columns,
      initialState: { pageIndex: 0, pageSize: 5 },
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card mt={5}>
      <CardHeader>
        {
          <HStack justifyContent="space-between">
            <Heading fontWeight="semibold" fontSize={20}>
              {title}
            </Heading>
            <HStack>{rightActions}</HStack>
          </HStack>
        }
      </CardHeader>
      <CardBody>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      textAlign={column.id === "actions" ? "center" : "left"}
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.length === 0 ? (
              <Tr>
                <Td colSpan={columns.length} textAlign="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    Nenhum registro encontrado
                  </Text>
                </Td>
              </Tr>
            ) : null}
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data;
                    if (cell.column.id === "actions") {
                      data = actions(cell.row.original);
                    } else if (cell.column.id === "status") {
                      if (cell.value === 1)
                        data = <Badge colorScheme="green">Ativo</Badge>;
                      else data = <Badge colorScheme="red">Inativo</Badge>;
                    } else {
                      data = (
                        <Text color={textColor} fontSize="sm">
                          {cell.render("Cell")}
                        </Text>
                      );
                    }

                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {pageEnable && pageCount > 1 && (
          <Box textAlign="center" justifyContent="center">
            <HStack spacing={3} alignItems="center">
              <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </Button>
              <Button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </Button>
              <Button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
              <span>
                Página{` `}
                <strong>
                  {pageIndex + 1} de {pageOptions.length}
                </strong>
              </span>
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 20, 100, 250, 500].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Exibir {pageSize}
                  </option>
                ))}
              </select>
            </HStack>
          </Box>
        )}
      </CardBody>
    </Card>
  );
}
