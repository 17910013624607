import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
  VStack,
  Checkbox,
} from "@chakra-ui/react";
import InputNumber from "components/InputNumber";
import useAuth, { UserPermissions } from "contexts/useAuth";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FiSettings } from "react-icons/fi";
import { editCustomerConfigurations } from "services/api.service";

export interface CustomerConfigurations {
  allowAutomaticWithdrawal: boolean;
  maxAutomaticWithdrawal: number;
  maxAutomaticDailyWithdrawal: number;
  onlyNowTicketsMovements: boolean;
  mainTaxesOnCharge: boolean;
  emailForChargesPaid: boolean;
}

const ConfigurationCustomers: React.FC<{
  customerId: string;
  config?: CustomerConfigurations;
  refetch: () => {};
}> = ({ customerId, config, refetch }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { hasPermission } = useAuth();
  const hasAutomaticPerm = hasPermission(UserPermissions.WITHDRAWALS_AUTOMATIC);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, register, control, reset, setValue } =
    useForm<CustomerConfigurations>();
  const save = async ({
    allowAutomaticWithdrawal,
    maxAutomaticWithdrawal,
    maxAutomaticDailyWithdrawal,
    onlyNowTicketsMovements,
    mainTaxesOnCharge,
    emailForChargesPaid,
  }: CustomerConfigurations) => {
    setLoading(true);
    editCustomerConfigurations(customerId, {
      allowAutomaticWithdrawal,
      maxAutomaticWithdrawal,
      maxAutomaticDailyWithdrawal,
      onlyNowTicketsMovements,
      emailForChargesPaid,
      mainTaxesOnCharge,
    })
      .then(() => {
        onClose();
        refetch();
        toast({
          title: "Sucesso",
          description: "Cliente atualizado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao atualizar cliente",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (config) {
      setValue(
        "allowAutomaticWithdrawal",
        config.allowAutomaticWithdrawal || false
      );
      setValue("maxAutomaticWithdrawal", config.maxAutomaticWithdrawal || 0);
      setValue(
        "maxAutomaticDailyWithdrawal",
        config.maxAutomaticDailyWithdrawal || 0
      );
      setValue(
        "onlyNowTicketsMovements",
        config.onlyNowTicketsMovements || false
      );
      setValue("mainTaxesOnCharge", config.mainTaxesOnCharge || false);
      setValue("emailForChargesPaid", config.emailForChargesPaid || false);
    }
  }, [isOpen, config, setValue]);

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          reset();
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FiSettings />
        <Text ml={2}>Configurações</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Editar Atributos</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <Checkbox
                  width="100%"
                  colorScheme="red"
                  {...register("onlyNowTicketsMovements")}
                >
                  Apenas Movimentos NowTicket
                </Checkbox>
                <Checkbox
                  width="100%"
                  colorScheme="red"
                  {...register("mainTaxesOnCharge")}
                >
                  Cobrar Taxas Principais
                </Checkbox>
                {hasAutomaticPerm && (
                  <>
                    <Checkbox
                      width="100%"
                      colorScheme="red"
                      {...register("allowAutomaticWithdrawal")}
                    >
                      Permitir Saques Automáticos
                    </Checkbox>
                    <InputNumber
                      label="Valor máximo de saque automático"
                      control={control}
                      name="maxAutomaticWithdrawal"
                      rules={{ required: true }}
                    />
                    <InputNumber
                      label="Valor máximo de saque automático diário"
                      control={control}
                      name="maxAutomaticDailyWithdrawal"
                      rules={{ required: true }}
                    />
                  </>
                )}
                <Checkbox
                  width="100%"
                  colorScheme="red"
                  {...register("emailForChargesPaid")}
                >
                  Enviar email de cobrança paga
                </Checkbox>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Salvar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ConfigurationCustomers;
