import { CheckIcon } from "@chakra-ui/icons";
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import React from "react";
import { useForm } from "react-hook-form";
import { finalizeCharge, findAllIntegrations } from "services/api.service";

const CompleteCharge: React.FC<{ id: string; refetch: () => void }> = ({
  id,
  refetch,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = React.useState(false);
  const cancelRef = React.useRef();
  const { control, handleSubmit } = useForm<{
    integrationId: SelectOptionType;
  }>();
  const toast = useToast();

  const handleCompletar = (data: { integrationId: SelectOptionType }) => {
    if (!data.integrationId) {
      toast({
        title: "Erro",
        description: "Selecione uma integração para completar o pagamento`",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    finalizeCharge(id, data.integrationId.value)
      .then(() => {
        setLoading(false);
        toast({
          title: "Sucesso",
          description: "Pagamento completado com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        refetch();
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Erro",
          description:
            err.response?.data?.message || "Erro ao completar pagamento",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  return (
    <>
      <Tooltip label="Atribuir Cliente à cobrança">
        <Button
          size="sm"
          colorScheme="green"
          ml={3}
          rounded={"sm"}
          border={"sm"}
          onClick={() => onOpen()}
          rightIcon={<CheckIcon />}
        >
          Finalizar
        </Button>
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Completar Pagamento
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja completar o pagamento?
              <FormRemoteSelectInput
                control={control}
                label="Cliente"
                loadDataFn={({ value, cb }) =>
                  findAllIntegrations({
                    companyId: "",
                    filter: value,
                    limit: 10,
                    page: 0,
                  }).then((retorno) => {
                    if (retorno.registers.length > 0) {
                      cb(
                        retorno.registers?.map((d: any) => ({
                          label: d.name,
                          value: d.id,
                        }))
                      );
                    } else {
                      cb([]);
                    }
                  })
                }
                required
                name="integrationId"
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fechar
              </Button>
              <Button
                colorScheme="red"
                onClick={handleSubmit(handleCompletar)}
                isLoading={loading}
                size={"sm"}
                ml={3}
              >
                Completar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default CompleteCharge;
