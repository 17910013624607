import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InputNumber from "components/InputNumber";
import React from "react";
import { useForm } from "react-hook-form";
import { FaLock } from "react-icons/fa";
import { lockFixed } from "services/api.service";
export interface ChangePasswordCustomerForm {
  amount: number;
}

const LockFixed: React.FC<{
  customerId: string;
  refetch: () => void;
}> = ({ customerId, refetch }) => {
  const {
    onOpen: onOpenChangePassword,
    isOpen: isOpenChangePassword,
    onClose: onCloseChangePass,
  } = useDisclosure();

  const [loading, setLoading] = React.useState(false);

  const toast = useToast();

  const { control, reset, handleSubmit } = useForm<ChangePasswordCustomerForm>({
    defaultValues: {
      amount: 0,
    },
  });

  const onClose = () => {
    onCloseChangePass();
    reset();
  };

  const onSubmit = (dataRequest: ChangePasswordCustomerForm) => {
    let request = lockFixed(customerId, dataRequest.amount);
    setLoading(true);
    request
      .then(() => {
        refetch();
        toast({
          title: "Succeso",
          description: `Bloqueio realizado com Sucesso`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err?.response?.data?.message,
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        onClick={onOpenChangePassword}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaLock />
        <Text ml={2}>Bloqueio Fixo</Text>
      </Button>
      {isOpenChangePassword && (
        <Portal>
          <Modal isOpen={isOpenChangePassword} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Bloqueio de valores em conta</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <VStack spacing={5}>
                    <InputNumber
                      control={control}
                      name="amount"
                      label="Valor de bloqueio"
                      placeholder="R$ 0,00"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />

                    <Button
                      isLoading={loading}
                      type="submit"
                      variant={"outline"}
                      w={`100%`}
                      color={"green"}
                    >
                      Salvar
                    </Button>
                  </VStack>
                </form>
              </ModalBody>

              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        </Portal>
      )}
    </>
  );
};

export default LockFixed;
