import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
// Custom components
import Card from "components/card/Card";
import FileSaver from "file-saver";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaUpload } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useDebounce } from "react-use";
import { findAllClients, findAllTerminals } from "services/api.service";
import FormRemoteSelectInput from "./FormRemoteSelectInput";
import InputForm from "./InputForm";
import TableList from "./Table";
import SelectForm from "./SelectForm";
import { DateTime } from "luxon";
import { PiFunnel } from "react-icons/pi";
import CustomButton from "./CustomButton";

export default function TableComponent(props: {
  queryFn: Function;
  columnsData: any[];
  exportPdf?: boolean;
  exportCsv?: boolean;
  queryKey: string;
  filterable?: string[];
  actions: any;
  right?: any;
  setSelectedRows?: any;
  enableSelect?: boolean;
  label?: string;
}) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const {
    columnsData,
    label,
    queryFn,
    queryKey,
    actions,
    right,
    filterable,
    setSelectedRows,
    enableSelect,
    exportPdf,
    exportCsv,
  } = props;

  const [filter, setFilter] = React.useState<{
    pageIndex: number;
    pageSize: number;
    orderBy: any[];
    filter: string;
    customerId?: string;
    maquinetaId?: string;
    companyId?: string;
    initialDate?: string;
    finalDate?: string;
    charges?: string;
    query?: Record<string, any>;
  }>({
    pageIndex: 0,
    pageSize: 20,
    orderBy: [],
    filter: "",
    ...(filterable?.includes("createdAt") && {
      initialDate: DateTime.now().startOf("month").toISO(),
      finalDate: DateTime.now().endOf("month").toISO(),
    }),
  });

  const loc = useLocation();

  const { control } = useForm();

  React.useEffect(() => {
    const params = new URLSearchParams(loc.search);
    const fil: any = {};
    filterable?.map((f) => {
      if (params.get(f)) {
        fil[f] = params.get(f);
      }
      return f;
    });

    setFilter((prev) => ({
      ...prev,
      ...fil,
    }));
  }, [filterable, loc.search]);

  const [busca, setBusca] = React.useState("");

  useDebounce(
    () => {
      setFilter((prev) => ({ ...prev, filter: busca }));
    },
    500,
    [busca]
  );

  const { data, isLoading } = useQuery<{
    pages: number;
    registers: any[];
  }>(
    [queryKey, filter],
    () =>
      queryFn({
        page: filter.pageIndex,
        filter: filter.filter,
        customerId: filter.customerId,
        companyId: filter.companyId,
        maquinetaId: filter.maquinetaId,
        limit: filter.pageSize,
        orderBy: filter.orderBy?.[0]?.id,
        direction: filter.orderBy?.[0]?.desc ? "desc" : "asc",
        initialDate: filter.initialDate,
        finalDate: filter.finalDate,
        query: filter.query,
        charges: filter.charges,
      }),
    {
      keepPreviousData: true,
    }
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Card flexDirection="column" w="100%" p={0} pt={5} pb={3} px="0px">
        <HStack
          px={5}
          justifyContent="space-between"
          flexDirection={{
            base: "column",
            md: "row",
          }}
        >
          <Text fontWeight={700} fontSize="xl">
            {label}
          </Text>
          <HStack
            px={5}
            justifyContent="flex-end"
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <CustomButton leftIcon={<PiFunnel />} onClick={onOpen}>
              Filtros
            </CustomButton>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Realizar filtros</DrawerHeader>

                <DrawerBody>
                  <VStack>
                    <InputForm
                      placeholder="Filtrar por palavras chaves..."
                      control={control}
                      name="filter"
                      onChange={(value) => setBusca(value)}
                      label="Filtrar"
                      type="text"
                      size="sm"
                    />

                    {columnsData
                      .filter((item) => item.filter)
                      .map((itemColumn) => {
                        return (
                          <>
                            <SelectForm
                              placeholder={`Filtrar por ${itemColumn.Header}...`}
                              control={control}
                              name={itemColumn.accessor}
                              label={itemColumn.Header}
                              onChange={(value) =>
                                setFilter((prev) => ({
                                  ...prev,
                                  query: {
                                    ...prev.query,
                                    [itemColumn.accessor]: value,
                                  },
                                }))
                              }
                              options={itemColumn.values.map((key) => ({
                                label: key.label,
                                value: key.value,
                              }))}
                            />
                          </>
                        );
                      })}

                    {filterable?.includes("createdAt") && (
                      <>
                        <InputForm
                          placeholder="Filtrar por palavras chaves..."
                          control={control}
                          name="initialDate"
                          label="Data Inicial"
                          type="datetime-local"
                          value={filter.initialDate?.substring(0, 16)}
                          size="sm"
                          onChange={(value) =>
                            setFilter((prev) => ({
                              ...prev,
                              initialDate: DateTime.fromISO(value).toISO(),
                            }))
                          }
                        />
                        <InputForm
                          placeholder="Filtrar por palavras chaves..."
                          control={control}
                          name="finalDate"
                          label="Data Final"
                          value={filter.finalDate.substring(0, 16)}
                          type="datetime-local"
                          size="sm"
                          onChange={(value) =>
                            setFilter((prev) => ({
                              ...prev,
                              finalDate: DateTime.fromISO(value).toISO(),
                            }))
                          }
                        />
                      </>
                    )}

                    {filterable?.includes("customerId") && (
                      <FormRemoteSelectInput
                        control={control}
                        name="customer"
                        label="Cliente"
                        size="sm"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            customerId: e.value,
                          }));
                        }}
                        loadDataFn={({ value, cb }) =>
                          findAllClients({
                            filter: value,
                            limit: 10,
                            page: 0,
                          }).then((retorno) => {
                            if (retorno?.registers?.length > 0) {
                              cb([
                                { label: "Escolher uma opção...", value: "" },
                                ...retorno.registers?.map((d: any) => ({
                                  label: d.name,
                                  value: d.id,
                                })),
                              ]);
                            } else {
                              cb([]);
                            }
                          })
                        }
                      />
                    )}

                    {filterable?.includes("onlyCharges") && (
                      <SelectForm
                        control={control}
                        name="charges"
                        label="Cobranças"
                        options={[
                          {
                            label: "Selecione...",
                            value: "",
                          },
                          {
                            label: "Apenas Cobranças",
                            value: "onlyCharges",
                          },
                          {
                            label: "Sem Cobranças",
                            value: "noCharges",
                          },
                        ]}
                        onChange={(e) => {
                          setFilter((prev) => ({ ...prev, charges: e }));
                        }}
                      />
                    )}

                    {filterable?.includes("maquinetaId") && (
                      <FormRemoteSelectInput
                        control={control}
                        name="maquineta"
                        label="Terminal"
                        size="sm"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            maquinetaId: e.value,
                          }));
                        }}
                        loadDataFn={({ value, cb }) =>
                          findAllTerminals({
                            filter: value,
                            limit: 10,
                            page: 0,
                            orderBy: [
                              {
                                id: "name",
                                desc: false,
                              },
                            ],
                          }).then((retorno) => {
                            if (retorno?.registers?.length > 0) {
                              cb([
                                { label: "Escolher uma opção...", value: "" },
                                ...retorno.registers?.map((d: any) => ({
                                  label: `${d.number} - ${d.name}`,
                                  value: d.id,
                                })),
                              ]);
                            } else {
                              cb([]);
                            }
                          })
                        }
                      />
                    )}
                    {exportPdf && (
                      <Button
                        leftIcon={<FaUpload />}
                        size="sm"
                        variant="outline"
                        colorScheme="blue"
                        isLoading={loading}
                        rounded={"sm"}
                        onClick={() => {
                          if (!filter.initialDate || !filter.finalDate) {
                            toast({
                              title: "Erro",
                              description: "Selecione uma data inicial e final",
                              status: "error",
                              duration: 9000,
                              position: "top",
                              isClosable: true,
                            });
                            return;
                          }
                          setLoading(true);
                          queryFn(
                            {
                              page: filter.pageIndex,
                              filter: filter.filter,
                              customerId: filter.customerId,
                              companyId: filter.companyId,
                              maquinetaId: filter.maquinetaId,
                              limit: filter.pageSize,
                              orderBy: filter.orderBy?.[0]?.id,
                              direction: filter.orderBy?.[0]?.desc
                                ? "desc"
                                : "asc",
                              initialDate: filter.initialDate,
                              finalDate: filter.finalDate,
                              query: filter.query,
                              charges: filter.charges,
                            },
                            true
                          )
                            .then((res: any) => {
                              setLoading(false);
                              FileSaver(res, "relatorio.pdf");
                            })
                            .catch((err) => {
                              setLoading(false);
                              toast({
                                title: "Erro",
                                description:
                                  "Ocorreu um erro ao gerar o relatório",
                                status: "error",
                                duration: 9000,
                                position: "top",
                                isClosable: true,
                              });
                            });
                        }}
                      >
                        Exportar PDF
                      </Button>
                    )}
                    {exportCsv && (
                      <Button
                        leftIcon={<FaUpload />}
                        size="sm"
                        isLoading={loading}
                        variant="outline"
                        colorScheme="blue"
                        rounded={"sm"}
                        onClick={() => {
                          if (!filter.initialDate || !filter.finalDate) {
                            toast({
                              title: "Erro",
                              description: "Selecione uma data inicial e final",
                              status: "error",
                              duration: 9000,
                              position: "top",
                              isClosable: true,
                            });
                            return;
                          }
                          setLoading(true);

                          queryFn(
                            {
                              page: filter.pageIndex,
                              filter: filter.filter,
                              customerId: filter.customerId,
                              companyId: filter.companyId,
                              maquinetaId: filter.maquinetaId,
                              limit: filter.pageSize,
                              orderBy: filter.orderBy?.[0]?.id,
                              direction: filter.orderBy?.[0]?.desc
                                ? "desc"
                                : "asc",
                              initialDate: filter.initialDate,
                              finalDate: filter.finalDate,
                              query: filter.query,
                              exportCsv: true,
                              charges: filter.charges,
                            },
                            false
                          )
                            .then((res: any) => {
                              setLoading(false);
                              FileSaver(res, "relatorio.csv", {
                                autoBom: false,
                              });
                            })
                            .catch((err) => {
                              setLoading(false);
                              toast({
                                title: "Erro",
                                description:
                                  "Ocorreu um erro ao gerar o relatório",
                                status: "error",
                                duration: 9000,
                                position: "top",
                                isClosable: true,
                              });
                            });
                        }}
                      >
                        Exportar CSV
                      </Button>
                    )}
                  </VStack>
                </DrawerBody>

                <DrawerFooter>
                  <Button variant="outline" mr={3} onClick={onClose}>
                    Fechar
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => {
                      setFilter({
                        pageIndex: 0,
                        pageSize: 20,
                        orderBy: [],
                        filter: "",
                        ...(filterable?.includes("createdAt") && {
                          initialDate: DateTime.now().startOf("month").toISO(),
                          finalDate: DateTime.now().endOf("month").toISO(),
                        }),
                      });
                      onClose();
                    }}
                  >
                    Limpar filtros
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
            {right}
          </HStack>
        </HStack>
        <Divider mt={5} />
        {isLoading ? (
          <Flex
            justify="center"
            align="center"
            h="100%"
            w="100%"
            minH={{ sm: "100px", lg: "200px" }}
            color="gray.500"
          >
            <Text>Carregando...</Text>
          </Flex>
        ) : (
          <TableList
            actions={actions}
            columnsData={columnsData}
            itensData={data?.registers || []}
            pageCount={data?.pages || 1}
            setFilter={setFilter}
            enableSelect={enableSelect}
            setSelectedRows={setSelectedRows}
          />
        )}
      </Card>
    </>
  );
}
