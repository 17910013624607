// Chakra imports
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  SimpleGrid,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";

import { useMemo } from "react";
import { cancelModel, findAllInsurancesModels } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import TableComponent from "components/TableComponent";
import { MdBlock } from "react-icons/md";
import { queryClient } from "index";
import { maskCurrency } from "utils/number";
import CreateModel from "../components/CreateModel";
import { FaList } from "react-icons/fa";
import { useHistory } from "react-router-dom";

export enum InsurancesStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  CANCELED = "CANCELED",
}

export default function InsurancesModels() {
  const toast = useToast();

  const history = useHistory();

  const columns = useMemo(
    () =>
      [
        {
          Header: "Seguro",
          accessor: "name",
        },
        {
          accessor: "pricePerDay",
          Header: "Valor Pago ",

          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: "Valor Diário",
          accessor: "amountPerDay",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: "Taxa de Juros",

          accessor: "fee",
          Cell: ({ value }) => {
            return value + "%";
          },
        },
        {
          accessor: "createdAt",
          Header: "Data de Cadastro",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: any) => (
    <ButtonGroup>
      <Tooltip label="Exluir modelo">
        <IconButton
          colorScheme="red"
          aria-label="Apertar"
          rounded="md"
          size="sm"
          variant="outline"
          icon={<MdBlock />}
          onClick={() =>
            cancelModel({
              id: row.id,
            })
              .then(() => {
                queryClient.refetchQueries({
                  queryKey: ["findAllModels"],
                  type: "active",
                });
              })
              .catch((err) => {
                toast({
                  title: "Erro ao alterar status",
                  description: err.message,
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
              })
          }
        />
      </Tooltip>
    </ButtonGroup>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          filterable={["companyId"]}
          right={
            <>
              <ButtonGroup>
                <CreateModel
                  onClose={() => {
                    queryClient.refetchQueries({
                      queryKey: ["findAllModels"],
                      type: "active",
                    });
                  }}
                />
                <Button
                  onClick={() => {
                    history.push("/admin/insurances");
                  }}
                  size="sm"
                  rounded="sm"
                  variant="outline"
                  colorScheme="brand"
                >
                  <FaList />
                  <Text ml={2}>Seguros</Text>
                </Button>

                <Button
                  onClick={() =>
                    queryClient.refetchQueries({
                      queryKey: ["findAllModels"],
                      type: "active",
                    })
                  }
                >
                  Atualizar
                </Button>
              </ButtonGroup>
            </>
          }
          queryFn={findAllInsurancesModels}
          queryKey="findAllModels"
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
