import { Tabs, TabList, Tab, TabPanels, TabPanel, Box } from "@chakra-ui/react";
import BlocksTab from "./components/BlocksTab";
import CustomerTab from "./components/CustomerTab";
import useAuth, { UserPermissions } from "contexts/useAuth";
import CardsTab from "./components/CardsTab";

const Custumer = () => {
  const { hasPermission, userData } = useAuth();
  const hasPerm = hasPermission(UserPermissions.BALANCES_READ);

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Tabs>
        <TabList>
          <Tab>Cliente</Tab>
          {hasPerm && <Tab>Bloqueios</Tab>}
          {/* <Tab>Taxas</Tab> */}
          {hasPerm && !userData.isCustomerAdmin ? <Tab>Cartões</Tab> : null}
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            <CustomerTab />
          </TabPanel>
          {hasPerm && (
            <TabPanel p={0}>
              <BlocksTab />
            </TabPanel>
          )}
          {hasPerm && !userData.isCustomerAdmin ? (
            <TabPanel p={0}>
              <CardsTab />
            </TabPanel>
          ) : null}
          {/* {!userData.isCustomerAdmin && (
            <TabPanel p={0}>
              <TaxesTab />
            </TabPanel>
          )} */}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Custumer;
