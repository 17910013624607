import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InputNumber from "components/InputNumber";
import PercentageNumberInput from "components/PercentageInput";
import React from "react";
import { useForm } from "react-hook-form";
import { freezeCash } from "services/api.service";
import { BalancesPending, FreezeMethods } from "types/Customers";
import { maskCurrency } from "utils/number";

export interface TaxesCustomerForm {
  amount: number;
  tax: number;
}

const ReleaseBlockBalances: React.FC<{
  data: BalancesPending;
  type: FreezeMethods;
  customerId: string;
  description: string;
  refetch: () => void;
  disabled?: boolean;
}> = ({ customerId, data, type, refetch, description, disabled }) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [loading, setLoading] = React.useState(false);
  const [effective, setEffective] = React.useState(false);

  const { control, handleSubmit, reset } = useForm<TaxesCustomerForm>({
    defaultValues: {
      amount: +data[type],
    },
  });

  const onSubmit = (data: TaxesCustomerForm) => {
    setLoading(true);
    freezeCash(customerId, { ...data, type, tax: data.tax || 0, effective })
      .then(() => {
        refetch();
        toast({
          title: "Sucesso",
          description: "Saldo liberado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description: err?.response?.data?.message || "Erro ao liberar saldo",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onOpenModal = (effective = false) => {
    onOpen();
    reset();
    setEffective(effective);
  };

  if (!data) return null;

  return (
    <>
      <VStack alignItems="start">
        <Text color="gray.500">
          {type === `lock` ? `Bloqueado` : `Pendente`} {description}
        </Text>
        <HStack>
          <Text>{maskCurrency(data[type]) || "---"}</Text>
          {+data[type] && type !== FreezeMethods.WITHDRAW && !disabled && (
            <>
              <Button
                variant="link"
                colorScheme="brand"
                onClick={() => onOpenModal()}
              >
                Liberar
              </Button>

              {type === `lock` && (
                <Button
                  variant="link"
                  colorScheme="brand"
                  onClick={() => onOpenModal(true)}
                >
                  Efetivar
                </Button>
              )}
            </>
          )}
        </HStack>
      </VStack>

      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Liberar valor</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={3}>
                  {type !== `lock` && (
                    <>
                      {" "}
                      <Text>
                        O valor será liberado e descontado a taxa que será
                        cobrada
                      </Text>
                      <PercentageNumberInput
                        control={control}
                        placeholder="5,99%"
                        label={"Valor da taxa "}
                        name={`tax`}
                        rules={{
                          required: "Campo obrigatório",
                          max: {
                            value: 100,
                            message: "Valor máximo de 100%",
                          },
                        }}
                      />
                    </>
                  )}
                  <InputNumber
                    control={control}
                    placeholder="R$ 10,00"
                    label={"Valor de liberação"}
                    name={`amount`}
                    rules={{
                      required: "Campo obrigatório",
                      max: {
                        value: data[type],
                        message: `Valor máximo de ${maskCurrency(data[type])}`,
                      },
                      min: {
                        value: 0.01,
                        message: `Valor mínimo de R$ 0,01`,
                      },
                    }}
                  />
                  <Button
                    isLoading={loading}
                    variant={"outline"}
                    type="submit"
                    w={`100%`}
                    color={"green"}
                  >
                    Salvar
                  </Button>
                </VStack>
              </form>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ReleaseBlockBalances;
