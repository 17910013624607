import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  VStack,
  IconButton,
  Tooltip,
  Button,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { FaSwatchbook } from "react-icons/fa";
import { syncPayments } from "services/api.service";

export interface Company {
  id: string;
  name: string;
  document: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IntegrationResponse {
  id: string;
  name: string;
  type: string;
  user: string;
  createdAt: Date;
  identifierUrl: string;
  active: boolean;
}

const SicoobIntegration: React.FC<{ data: IntegrationResponse }> = ({
  data,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleProcess = () => {
    syncPayments(data.id)
      .then(() => {
        toast({
          title: "Processando",
          description: "Processando lote de boletos",
          status: "info",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro ao processar lote",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      {data.type === "SICOOB" && (
        <Tooltip label="Dados de integração">
          <IconButton
            aria-label="Apertar"
            rounded="md"
            isDisabled={!data.identifierUrl}
            size="sm"
            variant="outline"
            icon={<FaSwatchbook />}
            onClick={onOpen}
          />
        </Tooltip>
      )}
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Processar Lotes</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack mb={5} spacing={3}>
                <Button colorScheme="brand" onClick={handleProcess}>
                  Processar últimos 2 dias
                </Button>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default SicoobIntegration;
