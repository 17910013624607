import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import React from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import FormLabelCustom from "./FormLabel";

const PercentageNumberInput: React.FC<{
  control: Control<any>;
  label: string;
  name: string;
  placeholder?: string;
  rules?: RegisterOptions<any>;
}> = ({ control, label, rules, name, placeholder }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <FormControl isInvalid={invalid}>
          <FormLabelCustom
            name={label}
            label={label}
            props={{}}
          ></FormLabelCustom>
          <NumericFormat
            decimalScale={4}
            prefix={""}
            suffix={" %"}
            name={name}
            value={value}
            onValueChange={(values) => {
              onChange(values.floatValue);
            }}
            inputMode={"numeric"}
            style={{
              height: "31px",
              borderRadius: "1px",
            }}
            decimalSeparator=","
            thousandSeparator="."
            placeholder={placeholder}
            customInput={Input as any}
            fixedDecimalScale={true}
            allowLeadingZeros={true}
          />
          <FormErrorMessage>
            {error?.message || "Campo inválido"}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default PercentageNumberInput;
